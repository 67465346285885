import uploadFile from "infrastructure/uploadFile";
import addOrUpdateDocument from "infrastructure/addOrUpdateDocument/addOrUpdateDocument";
import Client from "types/Client";
import API from "infrastructure/API";
import clientsIndex from "./clientsIndex";

export type NewClient = {
  isAdmin?: boolean;
  name: string;
  description: string;
  address: string;
  logo?: string;
  contactInfo: {
    name: string;
    phone: string;
    email: string;
  };
  state: string;
  categoryId?: string;
  loginEmail: string;
};

export type LoginInfo = {
  email: string;
  password: string;
};



async function addClient({
  client,
  loginInfo,
  isEditing,
  hasLoginInfoChanged,
  hasLogoChanged = true,
}: {
  loginInfo?: LoginInfo;
  client: NewClient | Client;
  isEditing?: boolean;
  hasLoginInfoChanged?: boolean;
  hasLogoChanged?: boolean;
}) {
  let user = null;
  if (!isEditing && loginInfo) {
    user = await API.createUser(loginInfo.email, loginInfo.password);
  } else {
    if (hasLoginInfoChanged && loginInfo) {
      user = await API.updateUser(
        //@ts-ignore
        client.userId,
        loginInfo.email,
        loginInfo.password
      );
    }
  }

  let ref: any = null;
  let downloadUrl = null;
  try {
    if (hasLogoChanged && client.logo) {
      const { ref: uRef, downloadUrl: dUrl } = await uploadFile(client.logo);
      ref = uRef;
      downloadUrl = dUrl;
    }

    let newClient = {
      ...client,
      logo: hasLogoChanged ? downloadUrl : client.logo,
    };
    if (hasLoginInfoChanged && loginInfo) {
      newClient.loginEmail = loginInfo?.email;
    }

    if (!isEditing) {
      //@ts-ignore
      newClient.userId = user.uid;
      //@ts-ignore
      newClient.rating = 0;
      //@ts-ignore
      newClient.ratingSum = 0;
      //@ts-ignore
      newClient.ratingCount = 0;
    }

    const result = await addOrUpdateDocument("clients", newClient);
    //@ts-ignore
    clientsIndex.addClient(result.id, newClient.name);
    return result;
  } catch (e) {
    ref && ref.delete();
    user && API.deleteUser(user.uid);
    API.log(e.message);
    throw new Error("Error al guardar Cliente, favor de intentar más tarde.");
  }
}

export default addClient;
