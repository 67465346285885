import React from "react";
import { Box, Text } from "grommet";
import { navigate, RouteComponentProps } from "@reach/router";
import { LinkNext, Trash } from "grommet-icons";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import { useMutation, queryCache } from "react-query";
import deleteReport from "domain/deleteReport";
import { toast } from "react-toastify";
import Card from "components/Card";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

interface IReport {
  route: string;
  timestamp: string;
  id: string;
}

const ViewReports: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  const { data: reports } = useFirestoreCollection<IReport>({
    collectionName: "adminReports",
  });

  const [deleteReportMutation] = useMutation(deleteReport, {
    onSuccess: () => {
      toast.success("Reporte eliminado");
      queryCache.invalidateQueries("adminReports");
    },
    onError: () => {
      toast.error("Error al eliminar reporte");
    },
  });

  return (
    <>
      <PageHeader title="Reportes de usuario" subtitle="" />
      <Card direction="column">
        {reports.map((r) => (
          <Box
            direction="row"
            border={{ side: "top", size: "1px" }}
            height={{ min: "40px" }}
            justify="between"
            align="center"
          >
            <Text>
              {new Date(r.timestamp).toLocaleDateString()}{" "}
              {new Date(r.timestamp).toLocaleTimeString()}
            </Text>
            <Box
              hoverIndicator
              focusIndicator
              onClick={() => deleteReportMutation({ reportId: r.id })}
            >
              <Trash color="status-error" />
            </Box>
            <Box
              focusIndicator
              hoverIndicator
              onClick={() => navigate(r.route)}
            >
              <LinkNext />
            </Box>
          </Box>
        ))}
        {reports.length === 0 && (
          <Text>Los reportes de tus usuarios aparecerán aqui.</Text>
        )}
      </Card>
    </>
  );
};

export default ViewReports;
