import { db } from "firebaseApp";

type Document = {
  id?: string;
};

export default async function addOrUpdateDocument<T>(
  collectioName: string,
  doc: T & Document
) {
  if (doc.id != null) {
    await db.collection(collectioName).doc(doc.id).update(doc);
    return doc;
  } else {
    return await db.collection(collectioName).add(doc);
  }
}
