import React, { useState } from "react";
import { Form, FormField, Button, Box, Image, Anchor } from "grommet";
import { auth } from "firebaseApp";
import { Login } from "grommet-icons";
import { RouteComponentProps, navigate } from "@reach/router";

import logo from "./logo.png";
import logger from "logger";
import { toast } from "react-toastify";
import Card from "components/Card";
import Loader from "components/Loader/Loader";
import authErrors from "domain/authErrors";

const LoginPage: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = useState(false);
  return (
    <Box
      a11yTitle="Log in Page"
      direction="column"
      align="center"
      gap="xlarge"
      flex="grow"
      id="login"
    >
      {loading && <Loader />}
      <Box
        margin={{ top: "large" }}
        justify="center"
        direction="row"
        flex={false}
      >
        <Image
          alt="p7black logo"
          src={logo}
          fit="contain"
          height="200px"
          width="200px"
        ></Image>
      </Box>
      <Card justify="center" width="80%">
        <Form
          onSubmit={(e: any) => {
            const { email, password } = e.value;
            if (email && password) {
              logger(`LOGIN_INTENTO ${email} `);
              setLoading(true);
              auth
                .signInWithEmailAndPassword(email.trim(), password)
                .then((u) => {
                  logger(`LOGIN_OK ${email} `);
                  setLoading(false);
                  return u;
                })
                .catch((e) => {
                  logger(`LOGIN_ERROR ${email} `);
                  toast.error(authErrors[e.code] || "Error al inciar sesión");
                })
                .finally(() => setLoading(false));
            }
          }}
        >
          <FormField
            margin="medium"
            name="email"
            label="Correo"
            type="email"
            data-cy="login-email"
            required
          ></FormField>
          <FormField
            margin="medium"
            name="password"
            type="password"
            label="Contraseña"
            data-cy="login-password"
            required
          ></FormField>
          <Button
            fill="horizontal"
            icon={<Login />}
            label="Iniciar Sesion"
            data-cy="login-submit"
            primary
            type="submit"
          ></Button>
        </Form>
        <Box
          alignSelf="center"
          margin={{ top: "medium" }}
          onClick={() => navigate("/resetPassword")}
        >
          <Anchor
            onClick={() => navigate("/resetPassword")}
            data-cy="login-forgot"
          >
            ¿Olvidaste tu contraseña?
          </Anchor>
        </Box>
      </Card>
    </Box>
  );
};

export default LoginPage;
