import React from "react";
import { Router } from "@reach/router";
import { Main } from "grommet";
import ResetPasswordPage from "pages/ResetPasswordPage/RessetPasswordPage";
import LoginPage from "pages/LoginPage/LoginPage";
import NotFound from "pages/NotFound";
import "./Routes.css";

const UnauthenticatedRountes: React.FC = () => {
  return (
    <Main align="center" pad={{ top: "medium" }}>
      <Router className="main_router">
        <ResetPasswordPage path="/resetPassword" />
        <LoginPage path="/" />
        <NotFound default></NotFound>
      </Router>
    </Main>
  );
};

export default UnauthenticatedRountes;
