import React, { Suspense, useState, useEffect } from "react";
import { Grommet } from "grommet";
import FirebaseAuthProvider from "./context/FirebaseAuthProvider";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ReactQueryConfigProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import Content from "components/Content/Content";
import "./App.css";

import theme from "./theme.json";
import Loader from "components/Loader/Loader";
import ErrorBoundary from "components/ErrorBoundary";
import atomos from "./atomos.svg";

const queryConfig = {
  shared: {
    suspense: true,
  },
  queries: {
    staleTime: 0,
    cacheTime: 5 * 60 * 1000,
    refetchAllOnWindowFocus: false,
  },
};

export const ThemeSwitchContext = React.createContext<{
  setTheme: (theme: "dark" | "light") => void;
}>({ setTheme: () => {} });

function App() {
  const [themeMode, setThemeMode] = useState<"light" | "dark">("dark");
  useEffect(() => {
    const root = document.getElementById("body");
    if (root) {
      root.style.backgroundColor = themeMode === "dark" ? "#322F33" : "#EEEEEE";
    }
  }, [themeMode]);
  return (
    <ErrorBoundary>
      <ReactQueryConfigProvider config={queryConfig}>
        <Suspense fallback={<Loader />}>
          <FirebaseAuthProvider>
            <ThemeSwitchContext.Provider value={{ setTheme: setThemeMode }}>
              <Grommet
                id="grommet"
                theme={theme}
                cssVars
                themeMode={themeMode}
                background={{
                  color: "background",
                  image: `url(${atomos})`,
                  position: "0% 0%",
                  repeat: "no-repeat",
                  size: "cover",
                }}
                style={{ backgroundAttachment: "fixed" }}
              >
                <Content />
              </Grommet>
            </ThemeSwitchContext.Provider>
            <ToastContainer hideProgressBar />
          </FirebaseAuthProvider>
        </Suspense>
      </ReactQueryConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </ErrorBoundary>
  );
}

export default App;
