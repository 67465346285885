import { useQuery } from "react-query";
import getDocumentsByIds from "infrastructure/getDocumentsByIds";

type Entity = {
  id: string;
};

export default function useFirestoreDocumentsByIds<T extends Entity>({
  collectionName,
  ids,
  staleTime,
  shouldSuspense = true,
}: {
  collectionName: string;
  ids: string[];
  staleTime?: number;
  shouldSuspense?: boolean;
}) {
  return useQuery(
    [collectionName, ...ids],
    () => {
      return getDocumentsByIds<T>(collectionName, ids);
    },
    { suspense: shouldSuspense }
  );
}
