import React, { useEffect, useState } from "react";

import { User } from "firebase";

import FirebaseAuthContext from "./FirebaseAuthContext";
import { auth, db } from "firebaseApp";
import Client from "types/Client";
import { AuthState } from "./AuthState";

const FirebaseAuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [client, setClient] = useState<Client | null>(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        db.collection("clients")
          .where("userId", "==", user.uid)
          .get()
          .then((snap) => {
            if (snap.docs[0]) {
              setClient({
                id: snap.docs[0].id,
                ...snap.docs[0].data(),
              } as Client);
            } else {
              throw new Error(`user id ${user.uid} not registered in clients`);
            }
          });
      } else {
        setClient(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
  const state: AuthState = {
    isLogged: user != null,
    user,
    client,
  };
  return (
    <FirebaseAuthContext.Provider value={state}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthProvider;
