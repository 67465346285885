import React from "react";
import { Box, Text } from "grommet";
import { navigate, RouteComponentProps } from "@reach/router";
import { Group, Indicator, Alert, Sort } from "grommet-icons";
import Card from "components/Card";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

const AdminPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  return (
    <Box direction="column" gap="medium">
      <PageHeader title="Administración" subtitle="" />
      <Card
        pad="medium"
        hoverIndicator
        direction="row"
        focusIndicator
        justify="between"
        onClick={() => navigate("/admin/clients")}
      >
        <Text>Administrar Clientes</Text>
        <Group />
      </Card>
      <Card
        pad="medium"
        direction="row"
        hoverIndicator
        justify="between"
        focusIndicator
        onClick={() => navigate("/admin/locations")}
      >
        <Text>Administrar Sedes</Text>
        <Indicator />
      </Card>
      <Card
        direction="row"
        pad="medium"
        justify="between"
        hoverIndicator
        focusIndicator
        onClick={() => navigate("/admin/categories")}
      >
        <Text>Administrar Categorias</Text>
        <Sort />
      </Card>
      <Card
        direction="row"
        pad="medium"
        justify="between"
        hoverIndicator
        focusIndicator
        onClick={() => navigate("/admin/reports")}
      >
        <Text>Ver Reportes</Text>
        <Alert color="status-warning" />
      </Card>
    </Box>
  );
};

export default AdminPage;
