import Client from "types/Client";
import { deleteFile } from "infrastructure/deleteFile";
import deleteDocuments from "infrastructure/deleteDocuments";
import deleteDocument from "infrastructure/deleteDocument";
import API from "infrastructure/API";
import { db } from "firebaseApp";
import clientsIndex from "./clientsIndex";

export default async function deleteClient({ client }: { client: Client }) {
  // delete logo
  if (client.logo) {
    await deleteFile(client.logo);
  }
  // delete comments
  await deleteDocuments("postComments", (f) =>
    f.where("clientId", "==", client.id)
  );

  // delete reviews
  // TODO: recalculate ratings
  await deleteDocuments("clientReviews", (f) =>
    f.where("reviewerId", "==", client.id)
  );

  // delete publications
  await deleteDocuments("posts", (f) => f.where("clientId", "==", client.id));
  // delete client
  try {
    await API.deleteUser(client.userId);
  } catch (e) {
    if (e.message !== "auth/user-not-found") {
      throw e;
    }
  }

  await deleteDocument("clients", client.id);
  await clientsIndex.removeClient(client);

  const locationsQuery = await db
    .collection("clients")
    .where("categoryId", "==", client.categoryId)
    .limit(1)
    .get();

  if (locationsQuery.empty) {
    //await deleteDocument("categories", client.categoryId);
  }
}
