import React from "react";
import { Box, DataTable, Text, Button } from "grommet";
import { RouteComponentProps } from "@reach/router";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import { Trash } from "grommet-icons";
import { useMutation, queryCache } from "react-query";
import { toast } from "react-toastify";
import Card from "components/Card";
import deleteCategory from "domain/deleteCategory";
import Category from "types/Category";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

const AdminCategoriesPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  const { data: categories } = useFirestoreCollection<Category>({
    collectionName: "categories",
  });

  const [deleteClientMutation] = useMutation(deleteCategory, {
    onSuccess: () => {
      toast.success("Categoria Eliminado");
      queryCache.invalidateQueries("categories");
    },
    onError: () => {
      toast.error(
        "Error al eliminar categoria, verificar si es que tiene clientes asignados"
      );
    },
  });

  return (
    <Card direction="column" align="center">
      <PageHeader title="Categorias de negocios, Proyecto 7" subtitle="" />

      <DataTable
        data={categories}
        columns={[
          { property: "name", header: <Text>Nombre</Text>, primary: true },
          {
            property: "id",
            header: <Text>Acciones</Text>,
            render: (datum: Category) => (
              <Box direction="row">
                <Button
                  focusIndicator
                  hoverIndicator
                  icon={<Trash color="status-error" />}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Seguro que desea eliminar la categoria? Esta accion no puede ser revertida"
                      )
                    ) {
                      deleteClientMutation({ category: datum });
                    }
                  }}
                />
              </Box>
            ),
          },
        ]}
      ></DataTable>
    </Card>
  );
};

export default AdminCategoriesPage;
