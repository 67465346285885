import React, { useContext } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Box, Grid, Text, Image, ResponsiveContext } from "grommet";
import Directorio from "./directorio.svg";
import Noticias from "./noticias.svg";
import Publicaciones from "./publicaciones.svg";
import Sedes from "./sedesp7.svg";
import logo from "./logo.png";
import useSwitchTheme from "useSwitchTheme";
import { Menu } from "grommet-icons";

const Home: React.FC<
  RouteComponentProps & {
    isExtended: boolean;
    toggleBar: () => void;
  }
> = ({ toggleBar, isExtended }) => {
  useSwitchTheme("dark");
  const size = useContext(ResponsiveContext);

  return (
    <Box
      direction="column"
      width="fill"
      justify="end"
      alignContent="between"
      flex={{ grow: 1, shrink: 0 }}
    >
      <Box>
        <Box
          focusIndicator={!isExtended}
          hoverIndicator={!isExtended}
          onClick={!isExtended ? toggleBar : () => {}}
          pad={"5px"}
          width="40px"
          height="40px"
          style={{ visibility: isExtended ? "hidden" : "visible" }}
          data-cy="menu-trigger"
        >
          <Menu color="brand" size="30px"></Menu>
        </Box>

        <Image
          alignSelf="center"
          src={logo}
          fit="contain"
          style={{ width: "100px", height: "100px" }}
          margin={{
            bottom: size === "small" ? "medium" : "medium",
            top: size === "small" ? "small" : "medium",
          }}
        ></Image>
      </Box>
      <Box
        flex="grow"
        justify="center"
        gap={size === "small" ? "small" : "medium"}
      >
        <MenuRow
          title="Publicaciones"
          description="Comparte, conecta, evoluciona!"
          icon={Publicaciones}
          testId="Publicaciones"
          onClick={() => {
            navigate("/publicaciones");
          }}
        />
        <MenuRow
          title="Directorio"
          description="Ubica, conecta, evoluciona!"
          icon={Directorio}
          testId="Directorio"
          onClick={() => {
            navigate("/directorio");
          }}
        />
        <MenuRow
          title="Noticias"
          description="Lee, participa, evoluciona!"
          icon={Noticias}
          testId="Noticias"
          onClick={() => {
            navigate("/noticias");
          }}
        />
        <MenuRow
          title="Sedes Proyecto 7"
          description="Encuéntranos, llega, evoluciona!"
          icon={Sedes}
          testId="Sedes"
          onClick={() => {
            navigate("/sedes");
          }}
        />
      </Box>
    </Box>
  );
};

const MenuRow: React.FC<{
  title: string;
  description: string;
  onClick?: () => void;
  icon: string;
  testId: string;
}> = ({ title, description, onClick, icon, testId }) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Box
      width="fill"
      pad="medium"
      onClick={onClick}
      round={size === "small" ? "none" : "small"}
      background="background-front"
      hoverIndicator
      focusIndicator
      data-cy={testId}
    >
      <Grid
        rows={["20px", "30px"]}
        gap="small"
        columns={["50px", "auto"]}
        areas={[
          { name: "icon", start: [0, 0], end: [0, 1] },
          { name: "name", start: [1, 0], end: [1, 0] },
          { name: "description", start: [1, 1], end: [1, 1] },
        ]}
      >
        <Box gridArea="icon">
          <Image alt="logo" width="50px" fit="contain" src={icon} />
        </Box>
        <Box gridArea="name">
          <Text size="small">{title}</Text>
        </Box>
        <Box gridArea="description">
          <Text size="10px">{description}</Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default Home;
