import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://c242f9eeb6c143d4a2830c42f79d0f47@o335319.ingest.sentry.io/5245561",
  });
  serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
      if (registration.waiting) {
        registration.waiting.addEventListener("statechange", (event: any) => {
          if (event.target.state === "activated") {
            window.location.reload();
          }
        });
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
  });
} else {
  serviceWorker.unregister();
}
