import React, { useState } from "react";
import {
  Box,
  Heading,
  Form,
  FormField,
  TextInput,
  Button,
  Select,
  CheckBox,
  TextArea,
} from "grommet";
import { RouteComponentProps, navigate } from "@reach/router";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import Category from "types/Category";
import { useMutation, queryCache } from "react-query";
import addClient, { NewClient, LoginInfo } from "domain/addClient";
import addCategory, { NewCategory } from "domain/addCategory";
import FileInput from "components/FileInput/FileInput";
import { toast } from "react-toastify";
import API from "infrastructure/API";
//import deleteDocument from "infrastructure/deleteDocument";
import Loader from "components/Loader/Loader";
import useSwitchTheme from "useSwitchTheme";
import Card from "components/Card";
import PageHeader from "components/PageHeader/PageHeader";
import authErrors from "domain/authErrors";

type AddClientMutationArgs = {
  newClient: NewClient;
  loginInfo: LoginInfo;
  newCategory?: NewCategory;
};
async function createClientMF({
  newClient,
  newCategory,
  loginInfo,
}: AddClientMutationArgs) {
  if (newCategory) {
    const result = await addCategory(newCategory);
    //@ts-ignore
    newClient.categoryId = result.id;
  }
  try {
    return await addClient({ client: newClient, loginInfo });
  } catch (e) {
    if (newCategory) {
      // deleteDocument("categories", newClient.categoryId as string);
    }
    throw e;
  }
}

const CreateClientPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  const { data: categories } = useFirestoreCollection<Category>({
    collectionName: "categories",
  });

  const [addClientMutation, { status }] = useMutation(createClientMF, {
    onSuccess: () => {
      toast.success("Cliente editado");
      queryCache.invalidateQueries(["clients"]);
      navigate("/admin/clients", { replace: true });
    },
    onError: (e: any) => {
      toast.error(authErrors[e?.message] || "Error al editar el cliente");
      API.log(e as string);
      navigate("/admin/clients", { replace: true });
    },
    useErrorBoundary: false,
  });

  const [category, setCategory] = useState<Category>();
  const [clientLogo, setClientLogo] = useState<string>();
  const [categoryLogo, setCategoryLogo] = useState<string>();
  const [isCreatingNewCategory, setNewCategory] = useState<boolean>(false);

  return (
    <>
      <PageHeader title="Nuevo Cliente" subtitle="" />

      <Card direction="column" gap="medium" pad="medium" fill>
        {status === "loading" && <Loader />}
        <Form
          onSubmit={(e: any) => {
            if (isCreatingNewCategory && categoryLogo == null) {
              toast.error("Debe incluir un icono de categoria");
              return;
            }
            //TODO: validate form
            const formData = e.value;
            let newClient: NewClient = {
              isAdmin: formData.isAdmin || false,
              name: formData.name,
              description: formData.description,
              address: formData.address,
              logo: clientLogo,
              contactInfo: {
                name: formData.contactName,
                phone: formData.contactPhone,
                email: formData.contactEmail,
              },
              state: formData.state,
              categoryId: category ? category.id : undefined,
              loginEmail: formData.email,
            };
            let args: AddClientMutationArgs = {
              newClient,
              loginInfo: {
                email: formData.email,
                password: formData.password,
              },
            };
            if (isCreatingNewCategory) {
              const newCategory: NewCategory = {
                name: formData.categoryName,
                icon: categoryLogo as string,
              };
              args.newCategory = newCategory;
            }
            return addClientMutation(args);
          }}
          onReset={() => setCategory(undefined)}
        >
          <FormField label="Nombre de empresa">
            <TextInput name="name" required />
          </FormField>
          <FormField label="Logo de empresa">
            <Box width="120px" height="150px" alignSelf="center">
              <FileInput name="logo" setFile={setClientLogo} />
            </Box>
          </FormField>
          <FormField label="Descripción">
            <TextArea name="description" required />
          </FormField>
          <FormField label="Direccion">
            <TextInput name="address" required />
          </FormField>
          <FormField label="Estado de la república">
            <TextInput name="state" />
          </FormField>

          <Heading level="3">Categoria de negocio</Heading>
          <Box margin={{ bottom: "small" }}>
            <CheckBox
              checked={isCreatingNewCategory}
              label="Crear Nueva categoria?"
              onChange={(event: any) => setNewCategory(event.target.checked)}
            />
          </Box>
          {isCreatingNewCategory ? (
            <>
              <FormField label="Nombre de categoria">
                <TextInput name="categoryName" />
              </FormField>
              <FormField label="Icono de categoria">
                <Box width="120px" height="150px" alignSelf="center">
                  <FileInput name="categoryFile" setFile={setCategoryLogo} />
                </Box>
              </FormField>
            </>
          ) : (
            <Select
              options={categories}
              value={category}
              labelKey={(o) => {
                return o.name;
              }}
              onChange={({ option }) => {
                setCategory(option);
              }}
            />
          )}
          <Heading level="3">Información de contacto</Heading>
          <FormField label="Nombre de representante">
            <TextInput name="contactName" required />
          </FormField>
          <FormField label="Teléfono">
            <TextInput name="contactPhone" required type="tel" />
          </FormField>
          <FormField label="Correo electrónico">
            <TextInput name="contactEmail" required type="email" />
          </FormField>
          <Heading level="3">Información de sesión</Heading>
          <FormField label="Correo electrónico">
            <TextInput name="email" type="email" required />
          </FormField>
          <FormField label="Contraseña">
            <TextInput name="password" type="password" required />
          </FormField>
          <Heading level="3">Administración</Heading>
          <Box margin={{ bottom: "small" }}>
            <CheckBox label="Es Administrador?" name="isAdmin" />
          </Box>
          <Box
            justify="center"
            direction="row"
            gap="medium"
            margin={{ top: "large" }}
          >
            <Button type="reset" color="status-error" label="Cancelar" />
            <Button primary color="status-ok" type="submit" label="Guardar" />
          </Box>
        </Form>
      </Card>
    </>
  );
};

export default CreateClientPage;
