import { db } from "firebaseApp";

export default function getFirestoreCollection<T>(
  collectionName: string,
  addFilters: (
    ref: firebase.firestore.CollectionReference
  ) => firebase.firestore.CollectionReference | firebase.firestore.Query = r =>
    r
) {
  return addFilters(db.collection(collectionName))
    .get()
    .then(snap => {
      const coll: T[] = [];
      snap.forEach(doc => {
        coll.push(({ id: doc.id, ...doc.data() } as unknown) as T);
      });
      return coll;
    });
}
