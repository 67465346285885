import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Paragraph } from "grommet";
import useAuthState from "infrastructure/hooks/useAuthState";
import Card from "components/Card";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

const MyInfo: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  const { client } = useAuthState();
  return (
    <>
      <PageHeader title="Mi información" subtitle="" />
      <Card>
        <Paragraph>Nombre: {client?.contactInfo.name}</Paragraph>
        <Paragraph>Correo: {client?.loginEmail}</Paragraph>
        <Paragraph size="small">
          Version: {process.env.REACT_APP_VERSION}
        </Paragraph>
      </Card>
    </>
  );
};

export default MyInfo;
