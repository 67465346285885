import { Box, Button, TextArea, Text, Form } from "grommet";
import React from "react";
import useAuthState from "infrastructure/hooks/useAuthState";
import addOrUpdateDocument from "infrastructure/addOrUpdateDocument/addOrUpdateDocument";
import Post from "types/Posts";
import { useMutation, queryCache } from "react-query";

type NewCommentType = {
  clientId: string;
  comment: string;
  timestamp: string;
  postId: string;
};
function isCommentValid(comment: string) {
  return typeof comment === "string" && comment.length > 0;
}
const NewComment: React.FC<{ post: Post }> = ({ post }) => {
  const { client } = useAuthState();
  const [addComment] = useMutation(
    ({ newComment }: { newComment: NewCommentType }) => {
      return addOrUpdateDocument("postComments", newComment);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["postComments"]);
      },
    }
  );

  if (client) {
    return (
      <Box background="background-contrast" round="small" pad="small">
        <Form
          onSubmit={(v: any) => {
            const comment = v.value.comment;
            if (isCommentValid(comment)) {
              const newComment: NewCommentType = {
                clientId: client.id,
                comment: v.value.comment,
                timestamp: new Date().toISOString(),
                postId: post.id,
              };
              addComment({ newComment });
            }
          }}
        >
          <Box gap="xsmall">
            <Text gridArea="name" weight="bold" size="small">
              {"Nuevo Comentario"}
            </Text>
            <Box gridArea="description">
              <TextArea
                name="comment"
                size="xsmall"
                resize={false}
                placeholder="comentario "
              ></TextArea>
            </Box>
            <Button primary type="submit" label="Guardar" />
          </Box>
        </Form>
      </Box>
    );
  } else {
    return null;
  }
};

export default NewComment;
