import React from "react";
import { Box, Text } from "grommet";
import { Alert } from "grommet-icons";
import API from "infrastructure/API";
import * as Sentry from "@sentry/browser";
import { Link } from "@reach/router";
import Card from "./Card";

export default class ErrorBoundary extends React.PureComponent<
  {},
  { error: boolean }
> {
  state = { error: false };

  static getDerivedStateFromError(error: any) {
    API.log(`ERROR: ${error.message}`);
    Sentry.captureException(error);
    return { error: true };
  }

  render() {
    if (this.state.error) {
      return (
        <Box fill align="center" justify="center">
          <Card width="70%">
            <Alert color="status-critical" size="large" />
            <Text>
              Ha ocurrido un error. El mismo ya fue reportado, da click
              <Link to="/" onClick={() => this.setState({ error: false })}>
                aqui
              </Link>{" "}
              para volver al inicio.
            </Text>
          </Card>
        </Box>
      );
    }

    return this.props.children;
  }
}
