import React, { useState, useRef, useCallback } from "react";
import { Box, Button, Image } from "grommet";

const FileInput: React.FC<{
  name: string;
  required?: boolean;
  setFile: (file: string) => void;
}> = ({ name, setFile, required = false }) => {
  const [imSrc, setSrc] = useState<string | null>(null);
  let fileInput = useRef<HTMLInputElement>(null);

  const onFileChange = useCallback(
    function noFileSelected(fileEvent: any) {
      if (fileEvent && fileEvent.target) {
        // @ts-ignore
        var selectedFile = fileEvent.target.files[0];
        if (selectedFile) {
          var reader = new FileReader();

          reader.onload = function (event) {
            // @ts-ignore
            setSrc(event.target.result);
            // @ts-ignore
            setFile(event.target.result);
          };

          reader.readAsDataURL(selectedFile);
        }
      }
    },
    [setSrc, setFile]
  );
  return (
    <Box
      fill
      background="status-disabled"
      alignContent="center"
      justify="center"
    >
      {imSrc && <Image alt="file" src={imSrc} height="medium" fit="contain" />}
      <input
        required={required}
        ref={fileInput}
        onChange={onFileChange}
        type="File"
        name={name}
        style={{ display: "none" }}
      />
      <Button
        size="small"
        label={imSrc ? "Cambiar" : "Agregar Imagen"}
        onClick={() => {
          if (fileInput.current) {
            fileInput.current.click();
          }
        }}
      ></Button>
    </Box>
  );
};

export default FileInput;
