import React from "react";
import { Box, Image, Heading, Paragraph, Text, Anchor } from "grommet";
import { RouteComponentProps } from "@reach/router";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import Loader from "components/Loader/Loader";
import ContactInformation from "components/ContactInformation/ContactInformation";
import Card from "components/Card";
import PageHeader from "components/PageHeader/PageHeader";
import sedesp7 from "components/Home/sedesp7.svg";
import useSwitchTheme from "useSwitchTheme";

const LocationPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  if (id == null) {
    throw new Error("id de cliente no valido");
  }
  useSwitchTheme("light");
  const { data: location } = useFirestoreDocument<ILocation>({
    collectionName: "locations",
    id,
  });
  if (location) {
    const { name, address, email, phone } = location;

    return (
      <>
        <PageHeader
          icon={sedesp7}
          title="Sedes P7"
          subtitle="Encuéntranos, llega, evoluciona!"
        />
        <Card direction="column" gap="large">
          <Box direction="row" justify="center">
            <Heading level={1} margin={{ left: "small" }}>
              {`SEDE ${name}`.toUpperCase()}
            </Heading>
          </Box>
          <Box gap="small">
            <Text>
              <u>Dirección:</u>
            </Text>
            <Paragraph margin={{ top: "0" }}>{address}</Paragraph>
            <u>Contacto:</u>
            <ContactInformation
              email={email}
              phone={phone}
            ></ContactInformation>
          </Box>
          <Box direction="row">
            <Image alt="logo" src={sedesp7} width="30px" height="30px" />
            <Anchor
              href={`https://maps.google.com/?q=${encodeURI(location.address)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Anchor
                href={`https://maps.apple.com/maps?q=${encodeURI(
                  location.address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text
                  alignSelf="center"
                  margin={{ left: "small", right: "small" }}
                >
                  Como llegar
                </Text>
              </Anchor>
            </Anchor>
          </Box>
        </Card>
      </>
    );
  } else {
    return <Loader />;
  }
};

export default LocationPage;
