import uploadFile from "infrastructure/uploadFile";
import addOrUpdateDocument from "infrastructure/addOrUpdateDocument/addOrUpdateDocument";

export type NewCategory = {
  name: string;
  icon: string; // data url
};

export default async function addCategory(category: NewCategory) {
  let fileUploaded: string | null = null;
  let ref: any = null;

  const { ref: uRef, downloadUrl } = await uploadFile(category.icon);
  ref = uRef;
  fileUploaded = downloadUrl;

  const newCategory: NewCategory = {
    name: category.name,
    icon: fileUploaded as string,
  };
  try {
    const result = await addOrUpdateDocument("categories", newCategory);
    return result;
  } catch (e) {
    ref && ref.delete();
    throw new Error("Error al guardar Categoria, favor de intentar más tarde.");
  }
}
