import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Text, Image, Box } from "grommet";

import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import icon from "./sedesp7.svg";
import IState from "types/IState";
import PageHeader from "components/PageHeader/PageHeader";
import useSwitchTheme from "useSwitchTheme";

const LocationsPage: React.FC<RouteComponentProps<{ stateId: string }>> = ({
  stateId: selectedStateId,
}) => {
  useSwitchTheme("light");
  // TODO: Add stale time
  const { data: states } = useFirestoreCollection<IState>({
    collectionName: "states",
    addFilters: (f) => f.orderBy("name", "asc"),
  });

  const { data: sedes } = useFirestoreCollection<ILocation>({
    collectionName: "locations",
    shouldFetch: selectedStateId != null,
    keys: { selectedArea: selectedStateId },
    shouldSuspense: false,
    addFilters: (f) => {
      return f
        .where("stateId", "==", selectedStateId || "test")
        .orderBy("name", "asc");
    },
  });

  return (
    <Box direction="column">
      <PageHeader
        icon={icon}
        title="Sedes P7"
        subtitle="Encuéntranos, llega, evoluciona!"
      />
      {selectedStateId == null
        ? states.map((c) => (
            <CategoryRow
              key={c.id}
              category={c}
              onClick={(c) => navigate(`sedes/${c.id}`)}
            />
          ))
        : sedes.map((s) => (
            <LocationRow
              key={s.id}
              location={s}
              onClick={(s) => navigate(`/sede/${s.id}`)}
            />
          ))}
    </Box>
  );
};

const LocationRow: React.FC<{
  location: ILocation;
  onClick: (location: ILocation) => void;
}> = ({ location, onClick }) => {
  const { name, stateName } = location;
  return (
    <Box
      background="background-front"
      direction="row"
      alignContent="center"
      pad={{ top: "medium", bottom: "medium", left: "small", right: "small" }}
      onClick={() => {
        onClick(location);
      }}
      hoverIndicator
      focusIndicator
    >
      <Box width="30px" height="30px">
        <Image alt="logo" src={icon} width="30px" height="30px" fit="contain" />
      </Box>
      <Box direction="column" alignSelf="center">
        <Text margin={{ left: "small" }}>{name}</Text>
        <Text size="small" margin={{ left: "small" }}>
          {stateName}
        </Text>
      </Box>
    </Box>
  );
};

const CategoryRow: React.FC<{
  category: IState;
  onClick: (category: IState) => void;
}> = ({ category, onClick }) => {
  const { name } = category;
  return (
    <Box
      background="background-front"
      direction="row"
      alignContent="center"
      pad={{ top: "medium", bottom: "medium", left: "small", right: "small" }}
      onClick={() => {
        onClick(category);
      }}
      hoverIndicator
      focusIndicator
    >
      <Box width="30px" height="30px">
        <Image alt="logo" src={icon} width="30px" height="30px" fit="contain" />
      </Box>
      <Text alignSelf="center" margin={{ left: "small" }}>
        {name}
      </Text>
    </Box>
  );
};

export default LocationsPage;
