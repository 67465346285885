import { Box } from "grommet";
import { Star } from "grommet-icons";
import React from "react";

const ViewRating: React.FC<{
  rating: number;
  onChange?: (rating: number) => void;
  size?: string;
}> = ({ rating, onChange, size = "20px" }) => {
  return (
    <Box direction="row">
      <Star
        size={size}
        color={rating >= 1 ? "brand" : "status-disabled"}
        onClick={() => onChange && onChange(1)}
        focusable
      />
      <Star
        size={size}
        color={rating >= 2 ? "brand" : "status-disabled"}
        onClick={() => onChange && onChange(2)}
        focusable
      />
      <Star
        size={size}
        color={rating >= 3 ? "brand" : "status-disabled"}
        onClick={() => onChange && onChange(3)}
        focusable
      />
      <Star
        size={size}
        color={rating >= 4 ? "brand" : "status-disabled"}
        onClick={() => onChange && onChange(4)}
        focusable
      />
      <Star
        size={size}
        color={rating >= 5 ? "brand" : "status-disabled"}
        onClick={() => onChange && onChange(5)}
        focusable
      />
    </Box>
  );
};

export default ViewRating;
