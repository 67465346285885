import { Box, Grid, Button, TextArea, Text, Form } from "grommet";
import React, { useState } from "react";
import useAuthState from "infrastructure/hooks/useAuthState";
import { useMutation, queryCache } from "react-query";
import addReview, { NewReview } from "domain/addReview";
import Client from "types/Client";
import ViewRating from "components/ViewRating/ViewRating";

function isCommentValid(comment: string) {
  return typeof comment === "string" && comment.length > 0;
}
const NewReviewForm: React.FC<{ client: Client }> = ({ client }) => {
  const { client: reviewer } = useAuthState();
  const [rating, setRating] = useState(0);
  const [addReviewMutation] = useMutation(addReview, {
    onSuccess: () => {
      queryCache.invalidateQueries(["clientReviews"]);
      setRating(0);
    },
  });

  if (reviewer) {
    return (
      <Box background="background-contrast" round="small" pad="medium">
        <Form
          onSubmit={(v: any) => {
            const comment = v.value.comment;
            if (isCommentValid(comment)) {
              const newReview: NewReview = {
                clientId: client.id,
                reviewerId: reviewer.id,
                comment: v.value.comment,
                timestamp: new Date().toISOString(),
                rating: rating,
              };
              addReviewMutation({review: newReview});
            }
          }}
        >
          <Grid
            rows={["40px", "70px"]}
            columns={["60%", "40%"]}
            areas={[
              { name: "name", start: [0, 0], end: [0, 0] },
              { name: "button", start: [1, 0], end: [1, 0] },
              { name: "description", start: [0, 1], end: [1, 1] },
            ]}
            gap="xsmall"
          >
            <Text gridArea="name" weight="bold" size="small">
              <ViewRating rating={rating} onChange={setRating} />
            </Text>
            <Button primary type="submit" label="Guardar" />
            <Box gridArea="description">
              <TextArea
                name="comment"
                size="xsmall"
                resize={false}
                fill
                placeholder="comentario "
              ></TextArea>
            </Box>
          </Grid>
        </Form>
      </Box>
    );
  } else {
    return null;
  }
};

export default NewReviewForm;
