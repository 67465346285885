import React, { useContext } from "react";
import styled from "styled-components";
import { Box, Text, ResponsiveContext } from "grommet";
import { RouteComponentProps } from "@reach/router";

const FooterContainer = styled.div`
  height: 3.1rem;
  width: 100%;
  flex-shrink: 0;
`;

const Footer: React.FC<RouteComponentProps> = () => {
  const size = useContext(ResponsiveContext);
  return (
    <FooterContainer>
      <Box
        background="brand"
        pad={{ top: "1px", bottom: "1px" }}
        justify="center"
        align="center"
        width="100%"
      >
        <Text size={size} color="#FFF">
          Evolucionamos la manera de hacer Networking
        </Text>
      </Box>
    </FooterContainer>
  );
};
export default Footer;
