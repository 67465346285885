import { useContext, useEffect } from "react";
import { ThemeSwitchContext } from "App";

export default function useSwitchTheme(theme: "light" | "dark") {
  const { setTheme } = useContext(ThemeSwitchContext);
  useEffect(() => {
    setTheme(theme);
    document.dispatchEvent(new CustomEvent<"light" | "dark">("themeSwitch", { detail: theme }))
  }, [theme, setTheme])
}
