import React from "react";
import Routes from "./Routes";
import UnauthenticatedRountes from "./UnauthenticatedRoutes";
import useAuthState from "infrastructure/hooks/useAuthState";

function getUserLevel(isLogged: boolean) {
  if (!isLogged) {
    return "NOT_LOGGED";
  }

  return "LOGGED";
}

const Content: React.FC = () => {
  const authState = useAuthState();

  const userLevel = getUserLevel(authState.isLogged);

  switch (userLevel) {
    case "NOT_LOGGED":
      return <UnauthenticatedRountes />;
    case "LOGGED":
      return <Routes />;
  }
};

export default Content;
