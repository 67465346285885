import deleteDocument from "infrastructure/deleteDocument";
import { db } from "firebaseApp";

export default async function deleteLocation({
  location,
}: {
  location: ILocation;
}) {
  await deleteDocument("locations", location.id);
  const locationsQuery = await db
    .collection("locations")
    .where("stateId", "==", location.stateId)
    .limit(1)
    .get();

  if (locationsQuery.empty) {
    await deleteDocument("states", location.stateId);
  }
}
