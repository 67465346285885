import { ClientsIndexDocument } from "domain/clientsIndex";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import useFirestoreDocumentsByIds from "infrastructure/hooks/useFirestoreDocumentsByIds";
import Client from "types/Client";

const useSearchClients = (searchInput: string) => {
  const { data: index } = useFirestoreDocument<ClientsIndexDocument>({
    collectionName: "clientsIndex",
    id: "clientsIndex",
  });
  const ids = index
    ? Object.values(index.data)
        .filter((c: any) => {
          return (
            searchInput.length > 0 &&
            c.name.toLowerCase().search(searchInput.toLowerCase()) >= 0
          );
        })
        .map((c: any) => c.id as string)
    : [];
  return useFirestoreDocumentsByIds<Client>({
    collectionName: "clients",
    ids,
    shouldSuspense: false,
  });
};

export default useSearchClients;
