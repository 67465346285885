import { Box, Grid, Text, Image } from "grommet";
import React from "react";
import Client from "types/Client";
import { navigate } from "@reach/router";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import { useMutation, queryCache } from "react-query";
import { toast } from "react-toastify";
import { Trash } from "grommet-icons";
import Review from "types/Review";
import deleteReview from "domain/deleteReview";
import ViewRating from "components/ViewRating/ViewRating";
import useAuthState from "infrastructure/hooks/useAuthState";

const ViewReview: React.FC<{ review: Review }> = ({ review }) => {
  const { data: reviewer } = useFirestoreDocument<Client>({
    collectionName: "clients",
    id: review.reviewerId,
    staleTime: 1000 * 60 * 60,
  });
  const { client } = useAuthState();

  const [deleteCommentMutation] = useMutation(deleteReview, {
    onSuccess: () => {
      toast.success("Comentario eliminado");
      queryCache.invalidateQueries(["postComments"]);
    },
    onError: () => {
      toast.error("Error al eliminar comentario");
    },
  });

  return (
    <Box background="background-contrast" round="small" pad="small">
      <Grid
        rows={["auto", "15px", "auto"]}
        columns={["20px", "100px", "auto"]}
        areas={[
          { name: "icon", start: [0, 0], end: [0, 0] },
          { name: "rating", start: [0, 1], end: [1, 1] },
          { name: "actions", start: [2, 1], end: [2, 1] },
          { name: "name", start: [1, 0], end: [2, 0] },
          { name: "description", start: [0, 2], end: [2, 2] },
        ]}
      >
        <Box
          gridArea="icon"
          onClick={() =>
            reviewer ? navigate(`/clients/${reviewer.id}`) : null
          }
        >
          {reviewer ? (
            <Image
              src={reviewer.logo}
              width="15px"
              height="15px"
              fit="contain"
            />
          ) : null}
        </Box>
        <Box
          hoverIndicator
          focusIndicator
          gridArea="name"
          onClick={() =>
            reviewer ? navigate(`/clients/${reviewer.id}`) : null
          }
        >
          <Text weight="bold" size="xsmall">
            {reviewer ? reviewer.name : "..."}
          </Text>
        </Box>
        <Box direction="row" justify="between" gridArea="actions">
          <Text size="0.5rem" color="grey">
            {new Date(review.timestamp).toLocaleDateString()}{" "}
            {new Date(review.timestamp).toLocaleTimeString()}
          </Text>
          {client?.isAdmin && (
            <Box
              onClick={() => deleteCommentMutation({review})}
              focusIndicator
              hoverIndicator
            >
              <Trash size="12px" color="status-error" />
            </Box>
          )}
        </Box>
        <Box gridArea="rating">
          <ViewRating size="15px" rating={review.rating} />
        </Box>

        <Text gridArea="description" size="xsmall">
          {review.comment}
        </Text>
      </Grid>
    </Box>
  );
};

export default ViewReview;
