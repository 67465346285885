import Client from "types/Client";
import { Box, Text, Image } from "grommet";
import { navigate } from "@reach/router";
import ViewRating from "./ViewRating/ViewRating";
import { Contact, Share } from "grommet-icons";
import React from "react";
import Card from "./Card";

const ClientCard: React.FC<{ client: Client }> = ({ client }) => {
  return (
    <Card
      direction="column"
      hoverIndicator
      focusIndicator
      onClick={() => navigate(`/clients/${client.id}`)}
    >
      <Box direction="row">
        {client.logo && (
          <Box width="110px" height="70px">
            <Image alt="client logo" src={client.logo} fit="contain" />
          </Box>
        )}
        <Box pad={{ left: "small" }}>
          <Text>{client.name}</Text>
        </Box>
      </Box>
      <Box justify="between" direction="row">
        <ViewRating rating={client.rating}></ViewRating>
        <Box direction="row" gap="small">
          <Contact />
          <Share />
        </Box>
      </Box>
    </Card>
  );
};

export default ClientCard;
