import deleteDocument from "infrastructure/deleteDocument";
import Review from "types/Review";
import getDocumentById from "infrastructure/getDocumentById";
import Client from "types/Client";
import addOrUpdateDocument from "infrastructure/addOrUpdateDocument/addOrUpdateDocument";

async function deleteReview({review}: {review: Review}) {
  const client = await getDocumentById<Client>("clients", review.clientId);
  let newRatingCount = client.ratingCount - 1;
  let newRatingSum = client.ratingSum - review.rating;
  let newRating = newRatingSum / newRatingCount;
  const newClient = {
    ...client,
    rating: newRating,
    ratingCount: newRatingCount,
    ratingSum: newRatingSum,
  };

  await addOrUpdateDocument("clients", newClient);

  return deleteDocument("clientReviews", review.id);
}

export default deleteReview;
