import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Form,
  FormField,
  TextInput,
  Button,
  Select,
  CheckBox,
  Text,
} from "grommet";
import { RouteComponentProps, navigate } from "@reach/router";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import Category from "types/Category";
import { useMutation, queryCache } from "react-query";
import { toast } from "react-toastify";
import API from "infrastructure/API";
import addState, { NewState } from "domain/addState";
import addLocation, { NewLocation } from "domain/addLocation";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import debounce from "infrastructure/debounce";
import mapsAPI from "infrastructure/mapsAPI";
import { Search } from "grommet-icons";
import ReactLoading from "react-loading";
import deleteDocument from "infrastructure/deleteDocument";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";
import Card from "components/Card";

type AddLocationMutationArgs = {
  newLocation: NewLocation;
  newState?: NewState;
};

const debouncedGeocode = debounce(
  500,
  (a: string, cb: (g: any) => void, before: () => void) => {
    before();
    mapsAPI.geocode(a).then(cb);
  }
)();

const EditLocationPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  useSwitchTheme("light");
  const { data: currentLocation } = useFirestoreDocument<ILocation>({
    collectionName: "locations",
    id: id || null,
  });
  const { data: states } = useFirestoreCollection<Category>({
    collectionName: "states",
  });

  const [addLocationMutation] = useMutation(
    async ({ newLocation, newState }: AddLocationMutationArgs) => {
      if (newState) {
        const result = await addState(newState);
        //@ts-ignore
        newLocation.stateId = result.id;
        newLocation.stateName = newState.name;
      }

      try {
        return await addLocation(newLocation);
      } catch (e) {
        if (newState) {
          deleteDocument("states", newLocation.stateId as string);
        }
        throw e;
      }
    },
    {
      onSuccess: () => {
        toast.success("Sede guardada");
        queryCache.invalidateQueries("locations");
        navigate("/admin/locations");
      },
      onError: (e) => {
        toast.error("Error al editar cliente");
        API.log((e as unknown) as string);
      },
    }
  );

  const [selectedLocation, setSelectedState] = useState<Category>();
  const [isCreatingNewState, setIsCreatingNewLocation] = useState<boolean>(
    false
  );
  const [address, setAddress] = useState<string>("");
  const [hasFoundAddress, setHasFoundAddress] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [geocoding, setGeocoding] = useState<any>();
  const [lat, setLat] = useState<string>("");
  const [lng, setLng] = useState<string>("");

  useEffect(() => {
    if (!hasFoundAddress) {
      debouncedGeocode(
        address,
        (r: any) => {
          setGeocoding(r);
          setLoadingAddress(false);
        },
        () => setLoadingAddress(true)
      );
    }
  }, [address, hasFoundAddress]);

  if (currentLocation) {
    return (
      <>
        <PageHeader title="Editar Cliente" subtitle="" />

        <Card direction="column" gap="medium">
          <Heading>Editar Sede</Heading>
          <Form
            onSubmit={(e: any) => {
              //TODO: validate form
              const formData = e.value;
              let newLocation: NewLocation = {
                name: formData.name,
                address: formData.address,
                phone: formData.contactPhone,
                email: formData.contactEmail,
                lat,
                lng,
                stateId: selectedLocation ? selectedLocation.id : undefined,
                stateName: selectedLocation ? selectedLocation.name : undefined,
              };
              let args: AddLocationMutationArgs = {
                newLocation: mergeLocations({
                  onlineLocation: currentLocation as ILocation,
                  formLocation: newLocation,
                }),
              };
              if (isCreatingNewState) {
                const newState: NewState = {
                  name: formData.stateName,
                };
                args.newState = newState;
              }
              return addLocationMutation(args);
            }}
            onReset={() => setSelectedState(undefined)}
          >
            <FormField label="Nombre de Sede">
              <TextInput
                name="name"
                required
                defaultValue={currentLocation.name}
              />
            </FormField>
            <FormField label="Dirección">
              <TextInput
                name="address"
                required
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setHasFoundAddress(false);
                }}
                icon={<Search />}
              />
              {loadingAddress && (
                <Box justify="center">
                  <ReactLoading
                    type="spin"
                    color="black"
                    height="1rem"
                    width="1rem"
                  />
                </Box>
              )}
              {geocoding && geocoding.status === "OK" && !hasFoundAddress && (
                <Box
                  focusIndicator
                  hoverIndicator
                  onClick={() => {
                    setAddress(geocoding.results[0].formatted_address);
                    setLat(geocoding.results[0].geometry.location.lat);
                    setLng(geocoding.results[0].geometry.location.lng);
                    setHasFoundAddress(true);
                  }}
                >
                  <Text size="small" wordBreak="break-word">
                    {geocoding.results[0].formatted_address}
                  </Text>
                </Box>
              )}
            </FormField>
            <Heading level="3">Estado de la república</Heading>
            <Box margin={{ bottom: "small" }}>
              <CheckBox
                checked={isCreatingNewState}
                label="Nuevo Estado?"
                onChange={(event: any) =>
                  setIsCreatingNewLocation(event.target.checked)
                }
              />
            </Box>
            {isCreatingNewState ? (
              <>
                <FormField label="Nombre">
                  <TextInput name="stateName" />
                </FormField>
              </>
            ) : (
              <Select
                options={states}
                value={selectedLocation}
                labelKey={(o) => {
                  return o.name;
                }}
                onChange={({ option }) => {
                  setSelectedState(option);
                }}
              />
            )}
            <Heading level="3">Información de contacto</Heading>
            <FormField label="Teléfono">
              <TextInput
                name="contactPhone"
                required
                type="tel"
                defaultValue={currentLocation.phone}
              />
            </FormField>
            <FormField label="Correo electrónico">
              <TextInput
                name="contactEmail"
                required
                type="email"
                defaultValue={currentLocation.email}
              />
            </FormField>
            <Box
              justify="center"
              direction="row"
              gap="medium"
              margin={{ top: "large" }}
            >
              <Button type="reset" color="status-error" label="Cancelar" />
              <Button primary color="status-ok" type="submit" label="Guardar" />
            </Box>
          </Form>
        </Card>
      </>
    );
  } else {
    return null;
  }
};

function mergeLocations({
  onlineLocation,
  formLocation,
}: {
  onlineLocation: ILocation;
  formLocation: NewLocation;
}): NewLocation {
  return {
    id: onlineLocation.id,
    name: formLocation.name || onlineLocation.name,
    address: formLocation.address || onlineLocation.address,
    stateId: formLocation.stateId || onlineLocation.stateId,
    phone: formLocation.phone || onlineLocation.phone,
    email: formLocation.email || onlineLocation.email,
    lat: formLocation.lat || onlineLocation.lat,
    lng: formLocation.lng || onlineLocation.lng,
  };
}

export default EditLocationPage;
