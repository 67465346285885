import React from "react";
import { Box, DataTable, Text, Button } from "grommet";
import { RouteComponentProps, navigate } from "@reach/router";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import { Trash, Edit, Add } from "grommet-icons";
import { useMutation, queryCache } from "react-query";
import { toast } from "react-toastify";
import deleteLocation from "domain/deleteLocation";
import Card from "components/Card";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

const AdminLocationsPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  const { data: locations } = useFirestoreCollection<ILocation>({
    collectionName: "locations",
  });

  const [deleteLocationMutation] = useMutation(deleteLocation, {
    onSuccess: () => {
      toast.success("Sede Eliminada");
      queryCache.invalidateQueries("locations");
    },
    onError: () => {
      toast.error("Error al eliminar sede");
    },
  });

  return (
    <>
      <PageHeader title="Sedes Proyecto 7" subtitle="" />

      <Card direction="column">
        <Button
          primary
          label="Nueva Sede"
          icon={<Add />}
          color="brand"
          onClick={() => navigate("/admin/locations/new")}
        />
        <DataTable
          data={locations}
          columns={[
            { property: "name", header: <Text>Nombre</Text>, primary: true },
            {
              property: "id",
              header: <Text>Acciones</Text>,
              render: (datum: ILocation) => (
                <Box direction="row">
                  <Button
                    focusIndicator
                    hoverIndicator
                    icon={<Edit />}
                    onClick={() => {
                      navigate(`/admin/locations/edit/${datum.id}`);
                    }}
                  />
                  <Button
                    focusIndicator
                    hoverIndicator
                    icon={<Trash color="status-error" />}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Seguro que desea eliminar la sede? Esta accion no puede ser revertida"
                        )
                      ) {
                        deleteLocationMutation({ location: datum });
                      }
                    }}
                  />
                </Box>
              ),
            },
          ]}
        ></DataTable>
      </Card>
    </>
  );
};

export default AdminLocationsPage;
