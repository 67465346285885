import { toast } from "react-toastify";
import addOrUpdateDocument from "infrastructure/addOrUpdateDocument/addOrUpdateDocument";
import uploadFile from "infrastructure/uploadFile";

export type SavePostParams = {
  clientId: string;
  title: string;
  desc: string;
  shortDescription: string;
  file: string | null;
};

export default function createPost(collectionName: string) {
  return async function ({
    title,
    desc,
    file,
    clientId,
    shortDescription,
  }: SavePostParams) {
    let fileUploaded: string | null = null;
    let ref: any = null;
    if (file) {
      try {
        const { ref: uRef, downloadUrl } = await uploadFile(file);
        ref = uRef;
        fileUploaded = downloadUrl;
      } catch (e) {
        toast.error(e.message);
        return;
      }
    }
    let newPost = {
      clientId,
      date: new Date().toISOString(),
      timestamp: Date.now(),
      description: desc,
      shortDescription,
      image: fileUploaded,
      title,
    };
    try {
      await addOrUpdateDocument(collectionName, newPost);
    } catch (e) {
      ref && ref.delete();
      toast.error("Error al guardar Publicación, favor de intentar más tarde.");
    }
  };
}
