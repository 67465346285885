import React from "react";
import { AuthState } from "./AuthState";

const FirebaseAuthContext = React.createContext<AuthState>({
  isLogged: false,
  user: null,
  client: null,
});

export default FirebaseAuthContext;
