import React from "react";
import { Box, Image, Heading, Paragraph, Text } from "grommet";
import { RouteComponentProps } from "@reach/router";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import Loader from "components/Loader/Loader";
import Client from "types/Client";
import ContactInformation from "components/ContactInformation/ContactInformation";
import ViewRating from "components/ViewRating/ViewRating";
import ClientReviews from "components/ClientReviews/ClientReviews";
import Card from "components/Card";
import directorioIcon from "components/Home/directorio.svg";
import PageHeader from "components/PageHeader/PageHeader";
import useSwitchTheme from "useSwitchTheme";

const ClientPage: React.FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  if (id == null) {
    throw new Error("id de cliente no valido");
  }
  useSwitchTheme("light");
  const { data: client } = useFirestoreDocument<Client>({
    collectionName: "clients",
    id,
  });
  if (client) {
    const { logo, name, description, address, contactInfo, rating } = client;
    return (
      <Box gap="medium">
        <PageHeader
          icon={directorioIcon}
          title="Directorio"
          subtitle="Ubica, conecta, evoluciona!"
        />

        <Card direction="column">
          {logo && (
            <Box height="80px">
              <Image
                alt="logo"
                fit="contain"
                margin={{ top: "23px" }}
                src={logo}
              />
            </Box>
          )}
          <Heading level={2}>
            <u>Empresa:</u> {name}
          </Heading>
          <Box gap="small">
            <Box>
              <u>Descripción:</u>
              <Paragraph>{description}</Paragraph>
            </Box>
            <Text>
              <u>Contacto:</u>
            </Text>
            <ContactInformation
              name={contactInfo.name}
              email={contactInfo.email}
              phone={contactInfo.phone}
            ></ContactInformation>
            <Box>
              <u>Sede:</u>
              <Paragraph>{address}</Paragraph>
            </Box>
            <Box
              direction="row"
              justify="between"
              border={{ side: "top", size: "1px" }}
              pad={{ top: "small" }}
            >
              <ViewRating rating={rating} />
            </Box>
            <ClientReviews client={client} />
          </Box>
        </Card>
      </Box>
    );
  } else {
    return <Loader />;
  }
};

export default ClientPage;
