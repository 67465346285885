import { Box, TextInput, Button } from "grommet";
import { Search } from "grommet-icons";
import React from "react";

const SearchBar: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  return (
    <Box direction="row" pad="small" background="background-front">
      <TextInput
        name="search"
        placeholder="Encuentra lo que necesitas!"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />

      <Button type="submit" icon={<Search />} />
    </Box>
  );
};

export default SearchBar;
