import { Box, Text } from "grommet";
import React from "react";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import ViewReview from "./ViewReview";
import Client from "types/Client";
import NewReviewForm from "./NewReviewComponent";
import Review from "types/Review";

const ClientReviews: React.FC<{ client: Client }> = ({ client }) => {
  const { data: comments } = useFirestoreCollection<Review>({
    collectionName: "clientReviews",
    addFilters: (f) => f.where("clientId", "==", client.id),
    keys: { clientId: client.id },
  });
  return (
    <Box width="100%" direction="column" pad={{ top: "small" }}>
      <Text> Reseñas: </Text>
      <Box direction="column" gap="small">
        <NewReviewForm client={client} />
        {comments.map((c, i) => (
          <ViewReview key={i} review={c} />
        ))}
      </Box>
    </Box>
  );
};

export default ClientReviews;
