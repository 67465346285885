/**
 * Miguel Espinoza
 * @miguespinoza
 */
import { useContext } from "react";
import FirebaseAuthContext from "context/FirebaseAuthContext";
import { AuthState } from "context/AuthState";

function useAuthState(): AuthState {
  const authState = useContext(FirebaseAuthContext);
  return authState;
}

export default useAuthState;
