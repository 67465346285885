import React, { useState } from "react";
import {
  Box,
  Heading,
  Form,
  FormField,
  TextInput,
  Button,
  Select,
  CheckBox,
} from "grommet";
import { RouteComponentProps, navigate } from "@reach/router";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import Category from "types/Category";
import { useMutation, queryCache } from "react-query";
import addClient, { LoginInfo } from "domain/addClient";
import addCategory, { NewCategory } from "domain/addCategory";
import FileInput from "components/FileInput/FileInput";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import Client from "types/Client";
import { toast } from "react-toastify";
import API from "infrastructure/API";
import useSwitchTheme from "useSwitchTheme";
import Loader from "components/Loader/Loader";
import Card from "components/Card";
import PageHeader from "components/PageHeader/PageHeader";
//import deleteDocument from "infrastructure/deleteDocument";

type EditClientMutationArgs = {
  newClient: Client;
  loginInfo?: LoginInfo;
  newCategory?: NewCategory;
  hasLogoChanged: boolean;
  hasLoginInfoChanged: boolean;
};

const EditClientPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  if (id == null) {
    throw new Error("Client id invalido");
  }
  useSwitchTheme("light");
  const { data: client } = useFirestoreDocument<Client>({
    collectionName: "clients",
    id: id,
  });

  const { data: categories } = useFirestoreCollection<Category>({
    collectionName: "categories",
  });

  const [editClientMutation, { status }] = useMutation(
    async ({
      newClient,
      newCategory,
      hasLoginInfoChanged,
      hasLogoChanged,
      loginInfo,
    }: EditClientMutationArgs) => {
      if (newCategory) {
        const result = await addCategory(newCategory);
        //@ts-ignore
        newClient.categoryId = result.id;
      }

      try {
        return await addClient({
          client: newClient,
          isEditing: true,
          loginInfo,
          hasLoginInfoChanged,
          hasLogoChanged,
        });
      } catch (e) {
        if (newCategory) {
          // deleteDocument("categories", newClient.categoryId as string);
        }
        throw e;
      }
    },
    {
      onSuccess: () => {
        toast.success("Cliente editado");
        queryCache.refetchQueries(["clients"]);
        navigate("/admin/clients");
      },
      onError: (e) => {
        toast.error("Error al editar cliente");
        API.log((e as unknown) as string);
      },
    }
  );

  const [category, setCategory] = useState<Category>();
  const [clientLogo, setClientLogo] = useState<string>();
  const [categoryLogo, setCategoryLogo] = useState<string>();
  const [isCreatingNewCategory, setNewCategory] = useState<boolean>(false);
  const [isEditingLogo, setIsEditingLogo] = useState<boolean>(false);
  const [isEditingLogin, setIsEditingLogin] = useState<boolean>(false);

  if (client) {
    return (
      <>
        <PageHeader title="Editar Cliente" subtitle="" />
        <Card direction="column" gap="medium" pad="medium">
          {status === "loading" && <Loader />}
          <Form
            onSubmit={(e: any) => {
              if (isCreatingNewCategory && categoryLogo == null) {
                toast.error("Debe incluir un icono de categoria");
                return;
              }
              if (isEditingLogo && clientLogo == null) {
                toast.error("Debe incluir el logo de la empresa");
                return;
              }
              //TODO: validate form
              const formData = e.value;
              // @ts-ignore
              let formClient: Client = {
                id,
                isAdmin: formData.isAdmin,
                name: formData.name,
                description: formData.description,
                address: formData.address,
                logo: clientLogo as string,
                contactInfo: {
                  name: formData.contactName,
                  phone: formData.contactPhone,
                  email: formData.contactEmail,
                },
                state: formData.state,
                categoryId: category
                  ? category.id
                  : ((undefined as unknown) as string),
              };

              const newClient = mergeClients({
                onlineClient: client,
                formClient,
              });

              let args: EditClientMutationArgs = {
                newClient,
                hasLoginInfoChanged: isEditingLogin,
                hasLogoChanged: isEditingLogo,
              };

              if (args.hasLoginInfoChanged) {
                args.loginInfo = {
                  email: formData.email,
                  password: formData.password,
                };
              }
              if (isCreatingNewCategory) {
                const newCategory: NewCategory = {
                  name: formData.categoryName,
                  icon: categoryLogo as string,
                };

                args.newCategory = newCategory;
              }
              return editClientMutation(args);
            }}
            onReset={() => setCategory(undefined)}
          >
            <FormField label="Nombre de empresa">
              <TextInput name="name" required defaultValue={client.name} />
            </FormField>
            <Box margin={{ bottom: "small" }}>
              <CheckBox
                checked={isEditingLogo}
                label="Cambiar logo de empresa?"
                onChange={(event: any) =>
                  setIsEditingLogo(event.target.checked)
                }
              />
            </Box>
            {isEditingLogo && (
              <FormField label="Logo de empresa">
                <Box width="120px" height="150px" alignSelf="center">
                  <FileInput name="logo" setFile={setClientLogo} />
                </Box>
              </FormField>
            )}
            <FormField label="Descripción">
              <TextInput
                name="description"
                required
                defaultValue={client.description}
              />
            </FormField>
            <FormField label="Direccion">
              <TextInput
                name="address"
                required
                defaultValue={client.address}
              />
            </FormField>
            <FormField label="Estado de la república">
              <TextInput name="state" defaultValue={client.state} />
            </FormField>

            <Heading level="3">Categoria de negocio</Heading>
            <Box margin={{ bottom: "small" }}>
              <CheckBox
                checked={isCreatingNewCategory}
                label="Crear Nueva categoria?"
                onChange={(event: any) => setNewCategory(event.target.checked)}
              />
            </Box>
            {isCreatingNewCategory ? (
              <>
                <FormField label="Nombre de categoria">
                  <TextInput name="categoryName" />
                </FormField>
                <FormField label="Icono de categoria">
                  <Box width="120px" height="150px" alignSelf="center">
                    <FileInput name="categoryFile" setFile={setCategoryLogo} />
                  </Box>
                </FormField>
              </>
            ) : (
              <Select
                options={categories}
                value={category}
                labelKey={(o) => {
                  return o.name;
                }}
                onChange={({ option }) => {
                  setCategory(option);
                }}
              />
            )}
            <Heading level="3">Información de contacto</Heading>
            <FormField label="Nombre de representante">
              <TextInput
                name="contactName"
                required
                defaultValue={client.contactInfo.name}
              />
            </FormField>
            <FormField label="Teléfono">
              <TextInput
                name="contactPhone"
                required
                type="tel"
                defaultValue={client.contactInfo.phone}
              />
            </FormField>
            <FormField label="Correo electrónico">
              <TextInput
                name="contactEmail"
                required
                type="email"
                defaultValue={client.contactInfo.email}
              />
            </FormField>
            <Heading level="3">Información de sesión</Heading>
            <Box margin={{ bottom: "small" }}>
              <CheckBox
                checked={isEditingLogin}
                label="Cambiar informacion de inicio de sesión?"
                onChange={(event: any) =>
                  setIsEditingLogin(event.target.checked)
                }
              />
            </Box>
            {isEditingLogin && (
              <>
                <FormField label="Correo electrónico" type="email">
                  <TextInput
                    name="email"
                    defaultValue={client.loginEmail}
                    required
                  />
                </FormField>
                <FormField label="Contraseña">
                  <TextInput name="password" type="password" required />
                </FormField>
              </>
            )}
            <Heading level="3">Administración</Heading>
            <Box margin={{ bottom: "small" }}>
              <CheckBox
                label="Es Administrador?"
                defaultChecked={client.isAdmin}
                name="isAdmin"
              />
            </Box>
            <Box
              justify="center"
              direction="row"
              gap="medium"
              margin={{ top: "large" }}
            >
              <Button type="reset" color="status-error" label="Cancelar" />
              <Button primary color="status-ok" type="submit" label="Guardar" />
            </Box>
          </Form>
        </Card>
      </>
    );
  } else {
    return null;
  }
};

/**
 * for crashes ob2 property will persist
 * @param ob1
 * @param ob2
 */
function mergeClients({
  onlineClient,
  formClient,
}: {
  onlineClient: Client;
  formClient: Client;
}): Client {
  return {
    userId: onlineClient.userId,
    id: onlineClient.id,
    name: formClient.name || onlineClient.name,
    description: formClient.description || onlineClient.description,
    address: formClient.address || onlineClient.address,
    state: formClient.state || onlineClient.state,
    logo: formClient.logo || onlineClient.logo,
    contactInfo: {
      name: formClient.contactInfo.name || onlineClient.contactInfo.name,
      phone: formClient.contactInfo.phone || onlineClient.contactInfo.phone,
      email: formClient.contactInfo.email || onlineClient.contactInfo.email,
    },
    categoryId: formClient.categoryId || onlineClient.categoryId,
    rating: onlineClient.rating,
    ratingCount: onlineClient.ratingCount,
    ratingSum: onlineClient.ratingSum,
    loginEmail: formClient.loginEmail || onlineClient.loginEmail,
  };
}

export default EditClientPage;
