import { storage } from "firebaseApp";
import getGUID from "./getGUID";

export default async function uploadFile(fileDataUrl: string) {
  const ref = storage.ref().child(getGUID());
  const snapshot = await ref.putString(fileDataUrl, "data_url");
  if (snapshot.state !== "success") {
    ref.delete();
    throw new Error("Error al subir imagen, favor de verificar archivo.");
  }
  const downloadUrl = await ref.getDownloadURL();
  return { ref, downloadUrl };
}
