function getMapsAPIKey() {
  return process.env.REACT_APP_api_key as string;
}

class MapsAPI {
  geocode(address: string) {
    if (address && address.length > 5) {
      return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
          address
        )}&region=mx&key=${getMapsAPIKey()}`
      ).then((r) => r.json());
    } else {
      return Promise.resolve({ status: "error" });
    }
  }
}

export default new MapsAPI();
