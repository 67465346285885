import addOrUpdateDocument from "infrastructure/addOrUpdateDocument/addOrUpdateDocument";

export type NewLocation = {
  id?: string;
  name: string;
  address: string;
  phone: string;
  email: string;
  lat: string;
  lng: string;
  stateId?: string;
  stateName?: string;
};

export default async function addLocation(location: NewLocation) {
  //generate locationsLink
  //@ts-ignore
  const newLocation: ILocation = {
    ...location,
    directionsLink: makeDirectionsURL(location.lat, location.lng),
  };
  return await addOrUpdateDocument("locations", newLocation);
}

function makeDirectionsURL(lat: string, lng: string) {
  return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
}
