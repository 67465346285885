import styled from "styled-components";
import React from "react";
import ReactLoading from "react-loading";

const Loader: React.FC = () => {
  return (
    <>
      <LoaderOverlay></LoaderOverlay>
      <LoaderContainer>
        <ReactLoading type="spin" color="black" />
      </LoaderContainer>
    </>
  );
};

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #dadada;
  opacity: 0.4;
`;

export default Loader;
