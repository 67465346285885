import { useQuery } from "react-query";
import getFirestoreCollection from "infrastructure/getFirestoreCollection/getFirestoreCollection";
import { isEmpty } from "ramda";

type Entity = {
  id: string;
};

type FirestoreCollectionQueryConf = {
  collectionName: string;
  addFilters?: (
    ref: firebase.firestore.CollectionReference
  ) => firebase.firestore.CollectionReference | firebase.firestore.Query;
  shouldFetch?: boolean;
  keys?: { [id: string]: any };
  shouldSuspense?: boolean;
  staleTime?: number;
};

function useFirestoreCollection<T extends Entity>({
  collectionName,
  addFilters = (r) => r,
  shouldFetch = true,
  keys = {},
  shouldSuspense = true,
  staleTime = 0,
}: FirestoreCollectionQueryConf) {
  const { data, status } = useQuery<T[], any>(
    isEmpty(keys) ? collectionName : [collectionName, keys],
    () => {
      return getFirestoreCollection<T>(collectionName, addFilters);
    },
    { suspense: shouldSuspense, enabled: shouldFetch }
  );

  const collection: T[] = data ? data : [];
  return { data: collection, status };
}

export default useFirestoreCollection;
