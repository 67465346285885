import React from "react";
import { Box, DataTable, Text, Button } from "grommet";
import { RouteComponentProps, navigate } from "@reach/router";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import Client from "types/Client";
import { Trash, Edit, Add } from "grommet-icons";
import { useMutation, queryCache } from "react-query";
import deleteClient from "domain/deleteClient";
import { toast } from "react-toastify";
import Card from "components/Card";
import useSwitchTheme from "useSwitchTheme";
import Loader from "components/Loader/Loader";
import PageHeader from "components/PageHeader/PageHeader";

const AdminClientsPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  const { data: clients } = useFirestoreCollection<Client>({
    collectionName: "clients",
  });

  const [deleteClientMutation, { status }] = useMutation(deleteClient, {
    onSuccess: () => {
      toast.success("Cliente Eliminado");
      queryCache.invalidateQueries("clients");
    },
    onError: () => {
      debugger;
      toast.error("Error al eliminar cliente");
    },
  });

  return (
    <>
      <PageHeader title="Clientes Proyecto 7" subtitle="" />

      <Card
        direction="column"
        align="center"
        style={{ wordBreak: "break-word" }}
      >
        {status === "loading" && <Loader />}
        <Button
          primary
          label="Nuevo Cliente"
          icon={<Add />}
          color="brand"
          onClick={() => navigate("/admin/clients/new")}
        />
        <DataTable
          data={clients}
          columns={[
            {
              property: "loginEmail",
              header: <Text>Nombre</Text>,
              primary: true,
            },
            {
              property: "id",
              header: <Text>Acciones</Text>,
              render: (datum: Client) => (
                <Box direction="row">
                  <Button
                    focusIndicator
                    hoverIndicator
                    icon={<Edit />}
                    onClick={() => {
                      navigate(`/admin/clients/edit/${datum.id}`);
                    }}
                  />
                  <Button
                    focusIndicator
                    hoverIndicator
                    icon={<Trash color="status-error" />}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Seguro que desea eliminar el cliente? Esta accion no puede ser revertida"
                        )
                      ) {
                        deleteClientMutation({ client: datum });
                      }
                    }}
                  />
                </Box>
              ),
            },
          ]}
        ></DataTable>
      </Card>
    </>
  );
};

export default AdminClientsPage;
