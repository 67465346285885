import { Box, Grid, Text, Image } from "grommet";
import React from "react";
import IComment from "types/IComment";
import Client from "types/Client";
import { navigate } from "@reach/router";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import { useMutation, queryCache } from "react-query";
import deleteComment from "domain/deleteComment";
import { toast } from "react-toastify";
import { Trash } from "grommet-icons";
import useAuthState from "infrastructure/hooks/useAuthState";

const Comment: React.FC<{ comment: IComment }> = ({ comment }) => {
  const { data: business } = useFirestoreDocument<Client>({
    collectionName: "clients",
    id: comment.clientId,
    staleTime: 1000 * 60 * 60,
  });

  const [deleteCommentMutation] = useMutation(deleteComment, {
    onSuccess: () => {
      toast.success("Comentario eliminado");
      queryCache.invalidateQueries(["postComments"]);
    },
    onError: () => {
      toast.error("Error al eliminar comentario");
    },
  });
  const { client } = useAuthState();

  return (
    <Box background="background-contrast" round="small" pad="small">
      <Grid
        rows={["auto", "15px", "auto"]}
        columns={["50px", "100px", "auto"]}
        areas={[
          { name: "icon", start: [0, 0], end: [0, 1] },
          { name: "date", start: [1, 1], end: [1, 1] },
          { name: "actions", start: [2, 1], end: [2, 1] },
          { name: "name", start: [1, 0], end: [2, 0] },
          { name: "description", start: [0, 2], end: [2, 2] },
        ]}
      >
        <Box
          gridArea="icon"
          onClick={() =>
            business ? navigate(`/clients/${business.id}`) : null
          }
        >
          {business ? (
            <Image
              alt="user logo"
              src={business.logo}
              width="50px"
              height="50px"
              fit="contain"
            />
          ) : null}
        </Box>
        <Box
          hoverIndicator
          focusIndicator
          gridArea="name"
          onClick={() =>
            business ? navigate(`/clients/${business.id}`) : null
          }
        >
          <Text weight="bold" size="xsmall">
            {business ? business.name : "..."}
          </Text>
        </Box>
        <Box gridArea="actions">
          {client?.isAdmin && (
            <Box
              onClick={() => deleteCommentMutation({ comment })}
              focusIndicator
              hoverIndicator
            >
              <Trash size="12px" color="status-error" />
            </Box>
          )}
        </Box>
        <Text gridArea="date" size="0.5rem" color="grey">
          {new Date(comment.timestamp).toLocaleDateString()}{" "}
          {new Date(comment.timestamp).toLocaleTimeString()}
        </Text>
        <Text gridArea="description" size="xsmall">
          {comment.comment}
        </Text>
      </Grid>
    </Box>
  );
};

export default Comment;
