import { useQuery } from "react-query";
import { db } from "firebaseApp";

type Entity = {
  id: string;
};

export default function useFirestoreDocument<T extends Entity>({
  collectionName,
  id,
  staleTime,
}: {
  collectionName: string;
  id: string | null;
  staleTime?: number;
}) {
  const response = useQuery(
    [collectionName, id],
    () => {
      return db
        .collection(collectionName)
        .doc(id as string)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const document: T = { id: doc.id, ...doc.data() } as T;
            return document;
          } else {
            console.error(`${id} not found`);
            throw new Error(`${id} not found`);
          }
        });
    },
    { enabled: id != null }
  );
  return response;
}
