import React, { useEffect, useCallback } from "react";
import { Box, Button, Nav, Sidebar, Image, ButtonProps } from "grommet";
import { auth } from "firebaseApp";
import {
  Home,
  Info,
  MailOption,
  UserPolice,
  Menu,
  Article,
  Power,
  UserExpert,
  Update,
} from "grommet-icons";
import { navigate } from "@reach/router";
import styled from "styled-components";
import useAuthState from "infrastructure/hooks/useAuthState";

const Bar = styled.div`
  position: fixed;
  transition: left 0.4s;
  left: ${(props: any) => (props.expanded ? "0" : "-254px")};
  z-index: 2;
  top: 0;
  > :first-child {
    border-radius: 0 !important;
  }
`;

const AppBar: React.FC<{
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}> = ({ isExpanded, setIsExpanded }) => {
  const collapseOnClickOutside = useCallback(
    (e: MouseEvent) => {
      const targetElement = e.target as HTMLElement;
      if (isExpanded && targetElement) {
        const menuBar = targetElement?.closest("#menuBar");
        if (!menuBar) {
          setIsExpanded(false);
        }
      }
    },
    [setIsExpanded, isExpanded]
  );

  useEffect(() => {
    document.addEventListener("click", collapseOnClickOutside);
    return () => document.removeEventListener("click", collapseOnClickOutside);
  }, [collapseOnClickOutside]);

  const { client } = useAuthState();
  return (
    <>
      {/* 
  // @ts-ignore */}
      <Bar expanded={isExpanded} id="menuBar">
        <Sidebar
          background="background-front"
          round="small"
          header={
            <Box direction="column" gap="large">
              <SidebarButton
                onClick={() => setIsExpanded(!isExpanded)}
                icon={<Menu color="brand" size="30px"></Menu>}
                data-cy="menu-close"
              ></SidebarButton>
            </Box>
          }
          footer={
            <SidebarButton
              icon={<Power color="brand" />}
              onClick={() => auth.signOut().then(() => navigate("/"))}
              label={"Cerrar Sesíon"}
              data-cy="menu-logout"
            ></SidebarButton>
          }
          height="100vh"
        >
          <Nav gap="small" responsive={false}>
            {client && (
              <Image
                alt="client logo"
                alignSelf="center"
                src={client.logo}
                width="30px"
                fit="contain"
              />
            )}
            <SidebarButton
              icon={<Home color="brand" />}
              label={"Inicio"}
              onClick={() => {
                setIsExpanded(false);
                navigate("/");
              }}
              data-cy="menu-home"
            ></SidebarButton>
            <SidebarButton
              icon={<Info color="brand" />}
              label={"Mi información"}
              onClick={() => {
                setIsExpanded(false);
                navigate("/myInfo");
              }}
              data-cy="menu-myInfo"
            ></SidebarButton>
            <SidebarButton
              icon={<MailOption color="brand" />}
              label={"Contacto"}
              data-cy="menu-contact"
              onClick={() => {
                setIsExpanded(false);
                navigate("/contact");
              }}
            ></SidebarButton>
            <SidebarButton
              icon={<UserPolice color="brand" />}
              label={"Políticas de uso"}
              data-cy="menu-policy"
              onClick={() => {
                setIsExpanded(false);
                navigate("/terms");
              }}
            ></SidebarButton>
            <SidebarButton
              icon={<Article color="brand" />}
              label={"Aviso de Privacidad"}
              data-cy="menu-privacy"
              onClick={() => {
                setIsExpanded(false);
                navigate("/privacyPolicy");
              }}
            ></SidebarButton>
            {client && client.isAdmin && (
              <SidebarButton
                icon={<UserExpert color="brand" />}
                label={"Administrar"}
                data-cy="menu-manage"
                onClick={() => {
                  setIsExpanded(false);
                  navigate("/admin");
                }}
              ></SidebarButton>
            )}
            <SidebarButton
              icon={<Update color="brand" />}
              label={"Actualizar"}
              data-cy="menu-update"
              onClick={() => {
                window.location.reload();
              }}
            ></SidebarButton>
          </Nav>
        </Sidebar>
      </Bar>
    </>
  );
};

type SidebarButtonProps = {
  onClick?: () => void;
};

const SidebarButton: React.FC<SidebarButtonProps & ButtonProps> = ({
  onClick,
  ...props
}) => (
  <Box
    align="start"
    pad="small"
    onClick={onClick}
    hoverIndicator
    focusIndicator
  >
    <Button gap="medium" plain {...props} focusIndicator={false} />
  </Box>
);

export default AppBar;
