import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Paragraph, Anchor } from "grommet";
import Card from "components/Card";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

const ContactUs: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  return (
    <Card>
      <PageHeader title="Contactanos" subtitle="" />
      <Paragraph>
        Cualquier duda o comentario, favor de comunicarse al siguiente correo
        electrónico:{" "}
        <Anchor href="tu.comunidad@p7black.com">
          tu.comunidad@p7black.com
        </Anchor>
      </Paragraph>
    </Card>
  );
};

export default ContactUs;
