import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Box, Image, Text, FormField, Button, Form } from "grommet";
import useAuthState from "infrastructure/hooks/useAuthState";
import { Add, Save } from "grommet-icons";
import { useRef, useState } from "react";
import { useMutation } from "react-query";
import createPost from "domain/createPost";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";

import WYSWYGEditor from "components/WYSWYGEditor/WYSWYGEditor";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

const CreatePostPage: React.FC<
  RouteComponentProps & {
    collectionName: string;
    onSuccessRedirect: string;
  }
> = ({ collectionName, onSuccessRedirect }) => {
  useSwitchTheme("light");

  const [imSrc, setSrc] = useState<string | null>(null);
  const [description, setDescription] = useState<string>("");

  const { client } = useAuthState();
  let fileInput = useRef<HTMLInputElement>(null);

  const [savePostMutation, { status }] = useMutation(
    createPost(collectionName),
    {
      onSuccess: () => {
        toast.success("Publicacion Creada!");
        navigate(onSuccessRedirect, { replace: true });
      },
    }
  );

  function noFileSelected(fileEvent: any) {
    if (fileEvent && fileEvent.target) {
      // @ts-ignore
      var selectedFile = fileEvent.target.files[0];
      if (selectedFile) {
        var reader = new FileReader();

        reader.onload = function (event) {
          // @ts-ignore
          setSrc(event.target.result);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  }

  if (client) {
    return (
      <Box>
        {status === "loading" && <Loader />}
        <Form
          onSubmit={(e: any) => {
            if (description.length === 0) {
              toast.error(
                "Por favor escribe una descripcion para tu publicacion."
              );
              return;
            }
            if (status !== "loading") {
              savePostMutation({
                title: e.value.title,
                desc: description,
                shortDescription: e.value.shortDescription,
                file: imSrc,
                clientId: client.id,
              });
            }
          }}
        >
          <Box background="background-front" pad="medium" gap="medium">
            <PageHeader title="Potencializa tu negocio" subtitle="" />

            <Box
              fill
              background="status-disabled"
              alignContent="center"
              justify="center"
            >
              {imSrc && (
                <Image
                  alt="post image"
                  src={imSrc}
                  height="medium"
                  fit="contain"
                />
              )}
              <input
                ref={fileInput}
                onChange={noFileSelected}
                type="File"
                name="image"
                style={{ display: "none" }}
              />
              <Button
                icon={<Add />}
                label={imSrc ? "Cambiar Imagen" : "Agregar Imagen"}
                onClick={() => {
                  if (fileInput.current) {
                    fileInput.current.click();
                  }
                }}
              ></Button>
            </Box>
            <FormField label="Titulo" name="title" required></FormField>
            <FormField
              label="Descripción Corta"
              name="shortDescription"
              required
            />
            <Text>Contenido</Text>
            <WYSWYGEditor onChange={setDescription} />

            <Button type="submit" primary label="Guardar" icon={<Save />} />
          </Box>
        </Form>
      </Box>
    );
  } else {
    return null;
  }
};

export default CreatePostPage;
