import { storage } from "firebaseApp";

export async function deleteFile(fileUrl: string) {
  const ref = storage.refFromURL(fileUrl);
  try {
    return await ref.delete();
  } catch (e) {
    if (e.code !== "storage/object-not-found") {
      throw e;
    }
  }
}
