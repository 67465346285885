import React from "react";
import { Box, Heading } from "grommet";
import { Link, RouteComponentProps } from "@reach/router";

const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <Box fill direction="column" justify="center" align="center">
      <Heading level="2">
        Ups, Algo malo ocurrio, da click <Link to="/">aqui</Link> para regresar
        al inicio
      </Heading>
    </Box>
  );
};

export default NotFound;
