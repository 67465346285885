let authErrors: { [id: string]: string } = {
  "auth/email-already-exists":
    "Otro usuario ya está utilizando el correo electrónico proporcionado.",
  "auth/invalid-email": "El correo electronico proporcionado no es valido.",
  "auth/user-not-found":
    "No existe ningún registro de usuario que corresponda al indicado.",
  "auth/invalid-password": "Contraseña invalida",
  "auth/wrong-password": "Contraseña invalida",
};
export default authErrors;
