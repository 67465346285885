import React from "react";
import { BoxProps, Box, ResponsiveContext } from "grommet";

const Card: React.FC<BoxProps & JSX.IntrinsicElements["div"]> = (props) => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Box
      pad="medium"
      round={size === "small" ? "none" : "small"}
      background="background-front"
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default Card;
