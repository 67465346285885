import { auth } from "firebaseApp";

function getApiEndpoint() {
  return process.env.REACT_APP_API as string;
}

function getLogApiEndpoint() {
  return process.env.REACT_APP_LOG as string;
}

class AppAPI {
  async getAuthHeaders() {
    const currentUser = auth.currentUser;
    const idToken = currentUser != null ? await currentUser.getIdToken() : "";
    return {
      "Content-Type": "application/json",
      authorization: `Bearer ${idToken}`,
    };
  }

  async createUser(email: string, password: string) {
    return fetch(`${getApiEndpoint()}/createUser`, {
      method: "post",
      body: JSON.stringify({ email, password }),
      headers: await this.getAuthHeaders(),
    })
      .then(async (r) => {
        if (r.ok) return r;
        else {
          const text = await r.json();
          throw new Error(text.code);
        }
      })
      .then((r) => r.json());
  }

  async updateUser(uid: string, email: string, password: string) {
    return fetch(`${getApiEndpoint()}/updateUser`, {
      method: "post",
      body: JSON.stringify({ uid, email, password }),
      headers: await this.getAuthHeaders(),
    }).then(async (r) => {
      if (r.ok) return r.json();
      else {
        const text = await r.json();
        throw new Error(text.code);
      }
    });
  }

  async deleteUser(uid: string) {
    return fetch(`${getApiEndpoint()}/deleteUser`, {
      method: "post",
      body: JSON.stringify({ uid }),
      headers: await this.getAuthHeaders(),
    }).then(async (r) => {
      if (r.ok) return r.status;
      else {
        const e = await r.json();
        throw new Error(e.code);
      }
    });
  }

  async log(message: string) {
    if (process.env.NODE_ENV === "production") {
      fetch(getLogApiEndpoint(), {
        method: "POST",
        body: `PROYECTO7: ${message} `,
        headers: { "Content-Type": "text/plain" },
      }).then(async (r) => {
        if (r.ok) return r.json();
        else {
          const text = await r.text();
          throw new Error(text);
        }
      });
    } else {
      console.error(message);
    }
  }
}

export default new AppAPI();
