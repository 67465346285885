import React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Box, FormField, Form, Button, Text } from "grommet";
import { auth } from "firebaseApp";
import { toast } from "react-toastify";
import Card from "components/Card";

const ResetPasswordPage: React.FC<RouteComponentProps> = () => {
  return (
    <Box fill align="center" justify="center">
      <Card width="300px" margin={{ top: "medium" }} pad="medium">
        <Text margin="medium">
          Ingresa tu correo electronico para restablecer tu contraseña
        </Text>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            const { email } = e.value;
            auth.sendPasswordResetEmail(email).then(() => {
              navigate("/");
              toast.success(
                "Recibirás un correo desde el cual podrás restanlecer tu contraseña"
              );
            });
          }}
        >
          <FormField name="email" label="Correo" required type="email" />
          <Box direction="row" margin={{ top: "medium" }}>
            <Button
              margin={{ right: "medium" }}
              onClick={() => navigate("/")}
              label="Regresar"
            ></Button>
            <Button type="submit" label="Enviar" primary={true}></Button>
          </Box>
        </Form>
      </Card>
    </Box>
  );
};

export default ResetPasswordPage;
