import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Paragraph, Heading } from "grommet";
import Card from "components/Card";
import useSwitchTheme from "useSwitchTheme";
import PageHeader from "components/PageHeader/PageHeader";

const TermsAndConditions: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  return (
    <>
      <PageHeader title="Terminos y condiciones de P7 Black" subtitle="" />

      <Card>
        <Heading level={3}>Proyecto 7 S.A. DE C.V.</Heading>
        <Paragraph>
          Este documento describe la forma en que recabamos y usamos los datos
          personales que usted nos brinda. También describe que tipo de datos
          son y que opciones tiene para limitar el uso de dichos datos, así como
          el procedimiento para ejercer sus derechos ARCO.
        </Paragraph>
        <Paragraph>
          1) Responsable de la protección de sus datos personales. PROYECTO 7
          S.A. DE C.V. con domicilio en Av. Hidalgo 64 5, El Pueblito
          Corregidora, Querétaro México, es responsable del tratamiento (uso) de
          sus datos personales.
        </Paragraph>
        <Paragraph>
          2) Nuestro Departamento de Protección de Datos Jefe del Departamento:
          Javier Martinez Domicilio: Av. Hidalgo 64 5, El Pueblito Corregidora,
          Querétaro México Correo electrónico: javier.martinez@proyecto7.com.mx
        </Paragraph>

        <Paragraph>
          3) ¿Para qué fines recabamos y utilizamos sus datos personales? Sus
          datos personales serán utilizados para las siguientes finalidades:
          Proveer los servicios de asesoría y consultoría profesional
          solicitados. Responder a sus requerimientos de información, atención y
          servicio. La creación, estudio, análisis, actualización, y
          conservación del expediente del servicio. Archivo de registros y
          expediente para seguimiento de servicios futuros. Gestión financiera,
          facturación y cobro. Dar cumplimiento a las obligaciones y compromisos
          que hemos contraído con usted. Adicionalmente, sus datos personales
          podrán ser utilizados para: Promoción y mercadeo de productos y
          servicios Ofrecerle nuevos productos y servicios a la medida de sus
          intereses y necesidades. Notificarle sobre nuevos servicios o
          productos que tengan relación con los ya contratados o adquiridos.
          Boletines y comunicados sobre nuestra organización.
        </Paragraph>

        <Paragraph>
          4) ¿Qué datos personales obtenemos y de dónde? Para las finalidades
          señaladas anteriormente podemos recabar sus datos personales de
          distintas formas:
          <ul>
            <li>
              Cuando visita nuestro sitio de Internet o utiliza nuestros
              servicios en línea.
            </li>
            <li>Por teléfono.</li>

            <li>Cuando usted nos los proporciona personalmente.</li>

            <li>
              Cuando obtenemos información a través de otras fuentes de acceso
              público que están permitidas por la ley (Directorios telefónicos o
              laborales, sitios web, etc).
            </li>
          </ul>
          – Datos personales que pudiéramos recabar:
          <ul>
            <li>
              Información de contacto (Nombre, Email, Dirección, Teléfono,
              Celular, Fax)
            </li>
            <li>
              Documentación de identidad (Credencial de Elector, Pasaporte,
              Licencia de Manejo, Cartilla o Cédula Profesional){" "}
            </li>

            <li>Información Fiscal (RFC, Dirección de Facturación)</li>

            <li>
              Credenciales de acceso a servicios en línea (Usuario y contraseña)
              Identificación en Redes Sociales (Facebook, Twitter, LinkedIn,
              Google+)
            </li>
            <li>Datos socioeconómicos (Edad, Sexo, Escolaridad).</li>
          </ul>
          Le informamos que para cumplir con las finalidades previstas en este
          aviso de privacidad, pudieran ser recabados y tratados los siguientes
          datos personales sensibles adicionalmente: Requerimos su
          consentimiento (aceptación) expreso para el tratamiento de sus datos
          personales sensibles, y nos comprometemos a que los mismos serán
          tratados bajo las más estrictas medidas de seguridad que garanticen su
          confidencialidad. Consiento y estoy de acuerdo en que mis datos
          personales sensibles sean tratados de acuerdo a los términos y
          condiciones del presente aviso de privacidad. Nombre y firma del
          titular
        </Paragraph>

        <Paragraph>
          5) ¿Cómo Acceder, Rectificar, Cancelar u Oponerse al uso y tratamiento
          de sus datos personales (Derechos ARCO) o revocar su consentimiento
          para el tratamiento sus datos? Usted tiene derecho constitucional de
          Acceder y conocer los datos personales que poseemos y a los detalles
          del tratamiento y uso de los mismos, así como a Rectificarlos y
          corregirlos en caso de ser inexactos o incompletos; Cancelarlos y
          eliminarlos cuando considere que no se requieren para alguna de las
          finalidades señalados en el presente aviso de privacidad, o que estén
          siendo utilizados para finalidades no autorizadas por usted o haya
          finalizado la relación contractual o de servicio, o bien, Oponerse al
          tratamiento de los mismos para fines específicos.
        </Paragraph>

        <Paragraph>
          Los mecanismos implementados para el ejercicio de sus Derechos ARCO,
          así como los plazos, información y documentación que debe contener su
          solicitud deben solicitarse al correo: rocio.bernal@proyecto7.com.mx
          Cómo puede limitar el uso divulgación de sus datos personales?
        </Paragraph>

        <Paragraph>
          Si usted desea dejar de recibir mensajes de nuestra parte que no sean
          indispensables para brindarle los servicios señalados, envíe su
          solicitud a nuestro Departamento de Protección de Datos Personales en
          donde le informaremos de los mecanismos implementados para ello.
        </Paragraph>

        <Paragraph>
          ¿Cómo revocar el consentimiento para el tratamiento de sus datos
          personales? Si usted desea revocar el consentimiento que nos otorgó
          para el uso de sus datos personales, envíe su solicitud a nuestro
          Departamento de Protección de Datos Personales en donde le
          informaremos de los mecanismos implementados para ello.
        </Paragraph>

        <Paragraph>
          6)Transferencia de datos personales Sus datos personales no serán
          Transferidos a terceras personas o empresas. Para cumplir con las
          finalidades establecidas en este Aviso de Privacidad, podrán ser
          únicamente Remitidos a personas o empresas que tengan el carácter de
          Encargados.
        </Paragraph>

        <Paragraph>
          7) Modificaciones al aviso de privacidad Nos reservamos el derecho de
          efectuar en cualquier momento modificaciones o actualizaciones al
          presente Aviso de Privacidad, para la atención de novedades
          legislativas, políticas internas o nuevos requerimientos para la
          prestación u ofrecimiento de nuestros servicios o productos. Estas
          modificaciones le serán informadas por las mismas vías utilizadas para
          recabar sus datos personales. Uso de cookies, web beacons y JavaScript
          Las cookies son una pequeña pieza de información enviada
          automáticamente por nuestro sitio web, las cuales son almacenadas en
          su navegador. De esta manera el sitio web puede consultar dicha
          información para conocer la actividad previa del usuario y agilizar
          algunos procedimientos como mantener la sesión o identificar que
          secciones del sitio ya visitó. Las web beacons en conjunto con los
          JavaScripts de métricas web permiten almacenar información sobre los
          patrones de uso de nuestro sitio web. Le informamos que utilizamos
          esas herramientas para obtener información estadística como la
          siguiente: Tipo de navegador y sistema operativo, las páginas de
          Internet consultadas, origen de la visita, la dirección IP de acceso,
          tiempo de permanencia en nuestro sitio, entre otros datos
          estadísticos. Las cookies, el JavaScript y otras tecnologías de
          seguimiento y rastreo pueden ser deshabilitadas, sin embargo,
          desactivarlas puede generar que el sitio web o algunas de sus
          funcionalidades no se desempeñen adecuadamente.
        </Paragraph>

        <Paragraph>
          8) ¿Ante quién puede presentar sus quejas y denuncias por el
          tratamiento indebido de sus datos personales? Si después de haber
          ejercido sus Derechos ARCO ante PROYECTO S.A. DE C.V. por medio de los
          mecanismos establecidos en este Aviso de Privacidad, usted considera
          que la respuesta ha sido insatisfactoria o incompleta; o presume que
          su derecho de protección de datos personales ha sido lesionado por
          alguna conducta o acción de nuestra parte; o cuenta con evidencia de
          que en el tratamiento de sus datos personales existe alguna violación
          a las disposiciones previstas en la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares, podrá interponer la queja
          o denuncia correspondiente ante el IFAI. Para mayor información
          visite: www.ifai.org.mx
        </Paragraph>
      </Card>
    </>
  );
};

export default TermsAndConditions;
