import { db } from "firebaseApp";
import Client from "types/Client";

export type ClientsIndexDocument = {
  id: string;
  data: {
    [id: string]: { id: string; name: string };
  };
};

class ClientsIndex {
  public getClientsIndex() {
    return db
      .collection("clientsIndex")
      .doc("clientsIndex")
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          const document = {
            id: doc.id,
            ...doc.data(),
          } as ClientsIndexDocument;
          return document;
        } else {
          throw new Error(`clients index not found`);
        }
      });
  }

  public async addClient(id: string, name: string) {
    const index = await this.getClientsIndex();
    index.data[id] = {
      id: id,
      name: name,
    };

    return await db
      .collection("clientsIndex")
      .doc("clientsIndex")
      .update(index);
  }

  public async removeClient(client: Client) {
    const index = await this.getClientsIndex();

    delete index.data[client.id];

    return await db
      .collection("clientsIndex")
      .doc("clientsIndex")
      .update(index);
  }
}

export default new ClientsIndex();
