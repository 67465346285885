import { Box, Text, Anchor } from "grommet";
import { Phone, MailOption, User } from "grommet-icons";
import React from "react";

const ContactInformation: React.FC<{
  name?: string;
  location?: string;
  phone: string;
  email: string;
}> = ({ name, phone, email, location }) => {
  return (
    <Box direction="column" gap="small">
      {name && (
        <Box direction="row" gap="small">
          <User color="brand" />
          <Text>{name}</Text>
        </Box>
      )}
      <Box direction="row" gap="small">
        <Phone color="brand" />
        <Text>
          <Anchor href={`tel:${phone}`}> {phone}</Anchor>
        </Text>
      </Box>
      <Box direction="row" gap="small">
        <MailOption color="brand" />
        <Text wordBreak="break-word">
          <Anchor href={`mailto:${email}`} > {email}</Anchor>
        </Text>
      </Box>
      {location && (
        <Box direction="row" gap="small" >
          <Text>Sede:</Text>
          <Text>{location}</Text>
        </Box>
      )}
    </Box>
  );
};

export default ContactInformation;
