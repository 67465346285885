import { deleteFile } from "infrastructure/deleteFile";
import deleteDocument from "infrastructure/deleteDocument";
import { db } from "firebaseApp";
import Category from "types/Category";

export default async function deleteCategory({
  category,
}: {
  category: Category;
}) {
  const locationsQuery = await db
    .collection("clients")
    .where("categoryId", "==", category.id)
    .limit(1)
    .get();

  if (locationsQuery.empty) {
    // delete logo
    if (category.icon) {
      await deleteFile(category.icon);
    }
    await deleteDocument("categories", category.id);
  } else {
    throw new Error(
      "La categoria no puede ser eliminada, ya que tiene clientes asignados a ella."
    );
  }
}
