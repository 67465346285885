import { Box, Heading } from "grommet";
import React from "react";
import NewComment from "components/NewComment/NewComment";
import IComment from "types/IComment";
import Comment from "components/Comment/Comment";
import Post from "types/Posts";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";

const PostComments: React.FC<{ post: Post }> = ({ post }) => {
  const { data: comments } = useFirestoreCollection<IComment>({
    collectionName: "postComments",
    addFilters: (f) => f.where("postId", "==", post.id),
    keys: { postId: post.id },
  });
  return (
    <Box width="100%" direction="column">
      <Heading level={4}> Comentarios:</Heading>
      <Box direction="column" gap="small">
        {comments.map((c, i) => (
          <Comment key={i} comment={c} />
        ))}

        <NewComment post={post} />
      </Box>
    </Box>
  );
};

export default PostComments;
