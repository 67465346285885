import React, { Suspense, useState } from "react";
import { Router } from "@reach/router";
import { Box, ResponsiveContext } from "grommet";
import AppBar from "components/AppBar/AppBar";
import Home from "components/Home/Home";
import DirectoryPage from "pages/DirectoryPage/DirectoryPage";
import PostsPage from "pages/PostsPage/PostsPage";
import CreatePostPage from "pages/CreatePostPage/CreatePostPage";
import LocationsPage from "pages/LocationsPage/LocationsPage";
import PostPage from "pages/PostPage/PostPage";
import Loader from "components/Loader/Loader";
import CategoryPage from "pages/CategoryPage/CategoryPage";
import ClientPage from "pages/ClientPage/ClientPage";
import NewsListPage from "pages/NewsListPage/NewsListPage";
import NewsPage from "pages/NewsPage/NewsPage";
import LocationPage from "pages/LocationPage/LocationPage";
import AdminPage from "pages/AdminPage/AdminPage";
import AdminLocationsPage from "pages/AdminPage/AdminLocationsPage";
import ViewReports from "pages/AdminPage/ViewReports";
import AdminClientsPage from "pages/AdminPage/AdminClientsPage";
import CreateClientPage from "pages/AdminPage/CreateClientPage";
import EditClientPage from "pages/AdminPage/EditClientPage";
import NotFound from "pages/NotFound";
import CreateLocationPage from "pages/AdminPage/CreateLocationPage";
import EditLocationPage from "pages/AdminPage/EditLocationPage";
import "./Routes.css";
import ContactUs from "pages/ContactUs";
import MyInfo from "pages/MyInfo";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsAndConditions from "pages/TermsAndConditions";
import AdminCategoriesPage from "pages/AdminPage/AdminCategories";
import Footer from "components/Footer";

function getResponsiveContentWidth(size: string) {
  switch (size) {
    case "small":
      return "100%";
    default:
      return "70%";
  }
}

const Routes: React.FC = () => {
  const size = React.useContext(ResponsiveContext);
  const [isExtended, setIsExtended] = useState(false);
  return (
    <Box id="routes" direction="row">
      <AppBar isExpanded={isExtended} setIsExpanded={setIsExtended}></AppBar>
      <Box
        align="center"
        style={{ zIndex: 1 }}
        direction="column"
        width="100%"
        height={{ min: "100vh" }}
        justify="center"
      >
        <Box
          width={getResponsiveContentWidth(size)}
          style={{ flex: "1 0 auto" }}
        >
          <Suspense fallback={<Loader />}>
            <Router width="100%" className="main_router">
              <Home
                path="/"
                isExtended={isExtended}
                toggleBar={() => setIsExtended(true)}
              ></Home>
              <DirectoryPage path="/directorio" />
              <CategoryPage path="/directorio/:id" />
              <ClientPage path="/clients/:id" />
              <PostsPage path="/publicaciones" />
              <PostPage path="/publicaciones/ver/:id" />
              <CreatePostPage
                path="/publicaciones/nueva"
                collectionName="posts"
                onSuccessRedirect="/publicaciones"
              />
              <CreatePostPage
                path="/noticias/nueva"
                collectionName="news"
                onSuccessRedirect="/noticias"
              />
              <LocationPage path="/sede/:id" />
              <LocationsPage path="/sedes" />
              <LocationsPage path="/sedes/:stateId" />
              <NewsListPage path="/noticias" />
              <NewsPage path="/noticias/ver/:id" />
              <AdminPage path="/admin" />
              <ViewReports path="/admin/reports" />
              <AdminLocationsPage path="/admin/locations" />
              <CreateLocationPage path="/admin/locations/new" />
              <EditLocationPage path="/admin/locations/edit/:id" />
              <AdminClientsPage path="/admin/clients" />
              <AdminCategoriesPage path="/admin/categories" />
              <CreateClientPage path="/admin/clients/new" />
              <EditClientPage path="/admin/clients/edit/:id" />
              <NotFound default></NotFound>

              <ContactUs path="/contact" />
              <MyInfo path="/myInfo" />
              <PrivacyPolicy path="/privacyPolicy" />
              <TermsAndConditions path="/terms" />
            </Router>
          </Suspense>
        </Box>
        <Router className="footer_router">
          <Footer path="/" />
        </Router>
      </Box>
    </Box>
  );
};

export default Routes;
