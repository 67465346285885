import "./WYSWYGEditor.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import React, { useState } from "react";
import draftToHtml from "draftjs-to-html";

const WYSWYGEditor: React.FC<{ onChange: (htmlContent: string) => void }> = ({
  onChange,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={(ns) => {
        setEditorState(ns);
        onChange(draftToHtml(convertToRaw(ns.getCurrentContent())));
      }}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      localization={{ locale: "es" }}
      toolbar={{
        options: ["inline", "blockType", "fontSize", "textAlign", "link"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
      }}
    />
  );
};

export default WYSWYGEditor;
