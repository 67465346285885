import Post from "types/Posts";
import deleteDocument from "infrastructure/deleteDocument";
import deleteDocuments from "infrastructure/deleteDocuments";
import { deleteFile } from "infrastructure/deleteFile";

function deletePost(deleteType: "posts" | "news") {
  return async function ({ post }: { post: Post }) {
    if (post.image) {
      await deleteFile(post.image);
    }
    if (deleteType === "posts") {
      await deleteDocuments("postComments", (f) =>
        f.where("postId", "==", post.id)
      );
    }
    const result = await deleteDocument(deleteType, post.id);
    return result;
  };
}

export default deletePost;
