import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Text, Image, Box } from "grommet";

import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import SearchBar from "components/SearchBar/SearchBar";
import Category from "types/Category";
import useSearchClients from "components/SearchClients/useSearchClients";
import { useState } from "react";
import ClientCard from "components/ClientCard";
import Card from "components/Card";
import PageHeader from "components/PageHeader/PageHeader";
import directorioIcon from "../../components/Home/directorio.svg";
import useDebouncedValue from "infrastructure/useDebouncedValue";
import useSwitchTheme from "useSwitchTheme";

const DirectoryPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  // TODO: Add stale time
  const { data: categories } = useFirestoreCollection<Category>({
    collectionName: "categories",
    addFilters: (f) => f.orderBy("name", "asc"),
  });
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebouncedValue<string>(searchTerm, 500);
  const { data: clients } = useSearchClients(debouncedSearchTerm);
  return (
    <>
      <PageHeader
        icon={directorioIcon}
        title="Directorio"
        subtitle="Ubica, conecta, evoluciona!"
      />
      <Card direction="column">
        <SearchBar value={searchTerm} onChange={setSearchTerm} />
        {clients?.length === 0 &&
          categories.map((c) => <CategoryRow key={c.id} category={c} />)}
        {clients?.map((c) => (
          <ClientCard key={c.id} client={c} />
        ))}
      </Card>
    </>
  );
};

const CategoryRow: React.FC<{ category: Category }> = ({
  category: { icon, id, name },
}) => {
  return (
    <Box
      direction="row"
      alignContent="center"
      background="background-front"
      border={{ side: "top", size: "1px", color: "dark-6" }}
      pad="10px"
      onClick={() => {
        navigate(`/directorio/${id}`);
      }}
      hoverIndicator
      focusIndicator
    >
      <Box width="30px" height="30px">
        <Image alt="category" src={icon} fit="contain" />
      </Box>
      <Text alignSelf="center" margin={{ left: "small" }}>
        {name}
      </Text>
    </Box>
  );
};

export default DirectoryPage;
