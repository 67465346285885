import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Box, Heading, Image, Text, Button, Anchor } from "grommet";
import { Trash } from "grommet-icons";
import PostComments from "components/PostComments/PostComments";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import Post from "types/Posts";
import Client from "types/Client";
import { useMutation } from "react-query";
import deletePost from "domain/deletePost";
import { toast } from "react-toastify";
import reportToAdmin from "domain/reportToAdmin";
import ViewRating from "components/ViewRating/ViewRating";
import ContactInformation from "components/ContactInformation/ContactInformation";
import useAuthState from "infrastructure/hooks/useAuthState";
import Card from "components/Card";
import PageHeader from "components/PageHeader/PageHeader";
import postsIcon from "components/Home/publicaciones.svg";
import styled from "styled-components";

const Article = styled.article`
  max-width: 100%;
`;

const PostPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
  uri,
}) => {
  if (id == null) {
    throw new Error("not valid post id");
  }
  const { data: post } = useFirestoreDocument<Post>({
    collectionName: "posts",
    id: id,
    staleTime: 1000 * 60 * 60,
  });
  const { data: client } = useFirestoreDocument<Client>({
    collectionName: "clients",
    id: post ? post.clientId : null,
    staleTime: 1000 * 60 * 60,
  });

  const { client: loggedClient } = useAuthState();

  const [deletePostMutation] = useMutation(deletePost("posts"), {
    onSuccess: () => {
      toast.success("Publicación eliminada");
      navigate("/publicaciones");
    },
    onError: () => {
      toast.error("Error al eliminar publicación");
    },
  });

  const [report] = useMutation(reportToAdmin, {
    onSuccess: () => {
      toast.success("Publicación reportada");
    },
    onError: () => {
      toast.error("Error al reportar publicación");
    },
  });

  if (post && client) {
    const { image, title, date, description } = post;
    return (
      <>
        <PageHeader
          icon={postsIcon}
          title="Publicaciones"
          subtitle="Comparte, conecta, evoluciona!"
        />
        <Card gap="medium">
          <Box direction="column" align="center" gap="medium">
            {image && <Image width="100%" fit="contain" src={image} />}
            <Box direction="column" align="start" width="100%" gap="medium">
              <Heading
                margin={{ top: "0", bottom: "0" }}
                level="3"
                size="large"
              >
                {title}
              </Heading>
              <Text size="10px" color="dark-4">
                {new Date(date).toLocaleDateString()}
              </Text>
              <Box direction="row" gap="small" align="center">
                <Box margin={{ top: "small" }}>
                  <Image fit="contain" src={client?.logo} width="40px"></Image>
                </Box>
                <Box>
                  <Anchor
                    color="text"
                    onClick={() => navigate(`/clients/${client.id}`)}
                  >
                    {client?.name}
                  </Anchor>
                  <ViewRating rating={client.rating} />
                </Box>
              </Box>

              <Article
                dangerouslySetInnerHTML={{ __html: description }}
              ></Article>
            </Box>
          </Box>
          <Box>
            <Text>
              <u>Contacto:</u>
            </Text>
            <ContactInformation
              name={client.contactInfo.name}
              email={client.contactInfo.email}
              phone={client.contactInfo.phone}
            />
          </Box>
          <Box direction="row" gap="small" wrap>
            <Button
              label="Reportar"
              color="status-warning"
              primary
              onClick={() => {
                report({ route: uri as string });
              }}
            />
            {loggedClient?.isAdmin && (
              <Button
                color="status-error"
                icon={<Trash />}
                label="Eliminar"
                onClick={() => {
                  if (
                    window.confirm("Seguro que desea eliminar la publicación?")
                  ) {
                    deletePostMutation({ post });
                  }
                }}
                primary
              />
            )}
          </Box>
          <Box gridArea="comments">
            <PostComments post={post} />
          </Box>
        </Card>
      </>
    );
  } else {
    return null;
  }
};

export default PostPage;
