import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Box, Heading, Image, Text, Button } from "grommet";
import { Trash } from "grommet-icons";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import Post from "types/Posts";
import { useMutation } from "react-query";
import deletePost from "domain/deletePost";
import { toast } from "react-toastify";
import reportToAdmin from "domain/reportToAdmin";
import logo from "./logo.png";
import useAuthState from "infrastructure/hooks/useAuthState";
import Card from "components/Card";
import PageHeader from "components/PageHeader/PageHeader";
import icon from "components/Home/noticias.svg";
import useSwitchTheme from "useSwitchTheme";
import styled from "styled-components";

const Article = styled.article`
  max-width: 100%;
`;

const NewsPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
  uri,
}) => {
  if (id == null) {
    throw new Error("not valid post id");
  }
  useSwitchTheme("light");
  const { data: post } = useFirestoreDocument<Post>({
    collectionName: "news",
    id: id,
    staleTime: 1000 * 60 * 60,
  });
  const { client } = useAuthState();

  const [deletePostMutation] = useMutation(deletePost("news"), {
    onSuccess: () => {
      toast.success("Publicación eliminada");
      navigate("/noticias");
    },
    onError: () => {
      toast.error("Error al eliminar publicación");
    },
  });

  const [report] = useMutation(reportToAdmin, {
    onSuccess: () => {
      toast.success("Publicación reportada");
    },
    onError: () => {
      toast.error("Error al reportar publicación");
    },
  });

  if (post) {
    const { image, title, date, description } = post;

    return (
      <>
        <PageHeader
          icon={icon}
          title="Noticias"
          subtitle="Lee, participa, evoluciona!"
        />
        <Card>
          <Box direction="column" gap="medium" align="center">
            {image && <Image fit="contain" src={image} width="100%" />}
            <Box direction="column" align="start" width="100%" pad="medium">
              <Heading
                margin={{ top: "0", bottom: "0" }}
                level="3"
                size="large"
              >
                {title}
              </Heading>
              <Text size="10px" color="dark-4">
                {new Date(date).toLocaleDateString()}
              </Text>
              <Box direction="row" gap="small" align="center">
                <Box margin={{ top: "small" }}>
                  <Image fit="contain" src={logo} width="40px"></Image>
                </Box>
                <Box>
                  <Text>Proyecto 7</Text>
                </Box>
              </Box>

              <Article
                dangerouslySetInnerHTML={{ __html: description }}
              ></Article>
            </Box>
          </Box>

          <Box direction="row" gap="small">
            <Button
              label="Reportar"
              color="status-warning"
              primary
              onClick={() => {
                report({ route: uri as string });
              }}
            />
            {client?.isAdmin && (
              <Button
                color="status-error"
                icon={<Trash />}
                label="Eliminar"
                onClick={() => {
                  if (
                    window.confirm("Seguro que desea eliminar la publicación?")
                  ) {
                    deletePostMutation({ post });
                  }
                }}
                primary
              />
            )}
          </Box>
        </Card>
      </>
    );
  } else {
    return null;
  }
};

export default NewsPage;
