import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Box,
  Image,
  Text,
  Paragraph,
  ResponsiveContext,
  Button,
  Heading,
} from "grommet";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import Post from "types/Posts";
import useFirestoreDocument from "infrastructure/hooks/useFirestoreDocument";
import Client from "types/Client";
import Card from "components/Card";
import PageHeader from "components/PageHeader/PageHeader";
import postsIcon from "components/Home/publicaciones.svg";
import useSwitchTheme from "useSwitchTheme";

const PostsPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");
  const { data: posts } = useFirestoreCollection<Post>({
    collectionName: "posts",
    addFilters: (f) => f.orderBy("timestamp", "desc"),
  });

  return (
    <Box direction="column">
      <PageHeader
        icon={postsIcon}
        title="Publicaciones"
        subtitle="Comparte, conecta, evoluciona!"
      />
      <Box>
        <Button
          primary
          margin="medium"
          onClick={() => navigate("/publicaciones/nueva")}
          label="Publica"
        />
      </Box>
      <Box gap="medium" margin={{ top: "medium" }}>
        {posts != null && posts.map((p) => <PostCard key={p.id} post={p} />)}
      </Box>
    </Box>
  );
};

const PostCard: React.FC<{ post: Post }> = ({ post }) => {
  const size = React.useContext(ResponsiveContext);
  const { data: client } = useFirestoreDocument<Client>({
    collectionName: "clients",
    id: post ? post.clientId : null,
    staleTime: 1000 * 60 * 60,
  });
  const { image, date, title, id, shortDescription } = post;
  return (
    <Card
      pad="none"
      onClick={() => navigate(`/publicaciones/ver/${id}`)}
      hoverIndicator
      focusIndicator
    >
      <Box direction="column" gap="medium" align="center">
        {image && (
          <Box height={{ max: size === "small" ? "small" : "medium" }}>
            <Image fit="cover" src={image} />
          </Box>
        )}
        <Box direction="column" align="start" width="100%" pad="medium">
          <Heading margin={{ top: "0", bottom: "0" }} level="3" size="large">
            {title}
          </Heading>
          <Text size="10px" color="dark-4">
            {new Date(date).toLocaleDateString()}
          </Text>
          <Box direction="row" gap="small" align="center">
            <Box margin={{ top: "small" }}>
              <Image fit="contain" src={client?.logo} width="40px"></Image>
            </Box>
            <Text>{client?.name}</Text>
          </Box>

          <Paragraph size="small" margin="small">
            {shortDescription}
          </Paragraph>
        </Box>
      </Box>
    </Card>
  );
};

export default PostsPage;
