import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box } from "grommet";
import Client from "types/Client";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import ClientCard from "components/ClientCard";
import PageHeader from "components/PageHeader/PageHeader";
import directorioIcon from "../../components/Home/directorio.svg";
import useSwitchTheme from "useSwitchTheme";

const CategoryPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  id,
}) => {
  if (id == null) {
    throw new Error("Id de categoria invalido");
  }
  useSwitchTheme("light");

  const { data: clients } = useFirestoreCollection<Client>({
    collectionName: "clients",
    addFilters: (f) => f.where("categoryId", "==", id),
    keys: { categoryId: id },
  });
  return (
    <Box gap="medium">
      <PageHeader
        icon={directorioIcon}
        title="Directorio"
        subtitle="Ubica, conecta, evoluciona!"
      />
      {clients.map((c) => (
        <ClientCard key={c.id} client={c} />
      ))}
    </Box>
  );
};

export default CategoryPage;
