import addOrUpdateDocument from "infrastructure/addOrUpdateDocument/addOrUpdateDocument";
import getDocumentById from "infrastructure/getDocumentById";
import Client from "types/Client";
export type NewReview = {
  clientId: string;
  reviewerId: string;
  rating: number;
  timestamp: string;
  comment: string;
};

async function addReview({review}:{ review: NewReview}) {
  const client = await getDocumentById<Client>("clients", review.clientId);

  let newRatingCount = client.ratingCount + 1;
  let newRatingSum = client.ratingSum + review.rating;
  let newRating = newRatingSum / newRatingCount;
  const newClient = {
    ...client,
    rating: newRating,
    ratingCount: newRatingCount,
    ratingSum: newRatingSum,
  };

  await addOrUpdateDocument("clients", newClient);

  await addOrUpdateDocument("clientReviews", review);
}

export default addReview;
