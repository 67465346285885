import React from "react";
import { Box, Image, Text } from "grommet";
import logo from "./logo.png";
import { LinkPrevious } from "grommet-icons";

const PageHeader: React.FC<{
  icon?: string;
  title: string;
  subtitle: string;
}> = ({ icon, title, subtitle }) => {
  return (
    <Box
      direction="column"
      background="background-transparent"
      round={{ size: "small", corner: "bottom" }}
      margin={{ bottom: "small" }}
    >
      <Box pad="small">
        <Box
          hoverIndicator
          focusIndicator
          onClick={() => window.history.back()}
          width="35px"
          data-cy="header-back"
        >
          <LinkPrevious size="30px" color="brand"></LinkPrevious>
        </Box>
      </Box>
      <Box
        direction="row"
        pad={{ left: "medium", right: "medium", bottom: "medium" }}
        width="100%"
        justify="between"
      >
        <Box direction="row" gap="medium">
          {icon && (
            <Box
              direction="column"
              gap="small"
              width="30px"
              flex={{ grow: 0, shrink: 1 }}
            >
              <Image alt="logo" src={icon} width="30px" fit="contain" />
            </Box>
          )}
          <Box direction="column">
            <Text size="large">{title}</Text>
            <Text size="small">{subtitle}</Text>
          </Box>
        </Box>
        <Box width="45px" height="45px" flex={{ grow: 0, shrink: 1 }}>
          <Image alt="logo" src={logo} fit="contain" />
        </Box>
      </Box>
    </Box>
  );
};

export default PageHeader;
