import { db } from "firebaseApp";

function getDocumentById<T>(collection: string, id: string) {
  return db
    .collection(collection)
    .doc(id)
    .get()
    .then((doc: any) => {
      if (doc.exists) {
        const document: T = { id: doc.id, ...doc.data() } as T;
        return document;
      } else {
        throw new Error(`Document with ${id} not found`);
      }
    });
}

export default getDocumentById;
