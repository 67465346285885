import * as React from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import {
  Box,
  Image,
  Text,
  Paragraph,
  ResponsiveContext,
  Button,
  Heading,
} from "grommet";
import logo from "./logo.png";
import useFirestoreCollection from "infrastructure/useFirestoreCollection";
import Post from "types/Posts";
import useAuthState from "infrastructure/hooks/useAuthState";
import icon from "components/Home/noticias.svg";
import PageHeader from "components/PageHeader/PageHeader";
import useSwitchTheme from "useSwitchTheme";
const NewsListPage: React.FC<RouteComponentProps> = () => {
  useSwitchTheme("light");

  const { data: news } = useFirestoreCollection<Post>({
    collectionName: "news",
    addFilters: (f) => f.orderBy("timestamp", "desc"),
  });
  const { client } = useAuthState();

  return (
    <>
      <PageHeader
        icon={icon}
        title="Noticias"
        subtitle="Lee, participa, evoluciona!"
      />
      <Box direction="column" gap="medium">
        {client?.isAdmin && (
          <Box>
            <Button
              primary
              margin="medium"
              onClick={() => navigate("/noticias/nueva")}
              label="Publica"
            />
          </Box>
        )}
        {news != null && news.map((p) => <NewsCard key={p.id} post={p} />)}
      </Box>
    </>
  );
};

const NewsCard: React.FC<{ post: Post }> = ({ post }) => {
  const size = React.useContext(ResponsiveContext);
  const { image, date, title, id, shortDescription } = post;
  return (
    <Box
      background="background-front"
      round="small"
      onClick={() => navigate(`/noticias/ver/${id}`)}
      hoverIndicator
      focusIndicator
      direction="column"
      gap="medium"
      align="center"
    >
      {image && (
        <Box height={{ max: size === "small" ? "small" : "medium" }}>
          <Image alt="logo" fit="cover" src={image} />
        </Box>
      )}
      <Box direction="column" align="start" width="100%" pad="medium">
        <Heading margin={{ top: "0", bottom: "0" }} level="3" size="large">
          {title}
        </Heading>
        <Text size="10px" color="dark-4">
          {new Date(date).toLocaleDateString()}
        </Text>
        <Box direction="row" gap="small" align="center">
          <Box margin={{ top: "small" }}>
            <Image fit="contain" src={logo} width="40px"></Image>
          </Box>
          <Text>Proyecto 7</Text>
        </Box>

        <Paragraph size="small" margin="small">
          {shortDescription}
        </Paragraph>
      </Box>
    </Box>
  );
};

export default NewsListPage;
